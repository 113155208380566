import { useMutation, useQueryClient } from "react-query";
import useOrganisationsApi from "./OrganisationsApi";
import { Organisation } from "./OrganisationsApiTypes";

const useOrganisationMutation = () => {
  const organisationsApi = useOrganisationsApi();
  const queryClient = useQueryClient();

  const updateOrganisation = useMutation({
    mutationFn: (organisation: Organisation) => {
      return organisationsApi.update(organisation);
    },
    onSuccess: (data, variables) => {
      const { id } = variables;
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
      queryClient.invalidateQueries({ queryKey: ["organisation", id] });
    },
  });

  const inviteUser = useMutation({
    mutationFn: (options: {
      organisationId: string;
      email: string;
      role: string;
    }) => {
      return organisationsApi.inviteUser(options);
    },
    onSuccess: (data, variables) => {
      const { organisationId } = variables;
      queryClient.invalidateQueries({
        queryKey: ["organisationInvitations", organisationId],
      });
    },
  });

  const resendInvite = useMutation({
    mutationFn: (options: { organisationId: string; inviteId: string }) => {
      return organisationsApi.resendInvite(options);
    },
    onSuccess: (data, variables) => {
      const { organisationId } = variables;
      queryClient.invalidateQueries({
        queryKey: ["organisationInvitations", organisationId],
      });
    },
  });

  const deleteInvite = useMutation({
    mutationFn: (options: { organisationId: string; inviteId: string }) => {
      return organisationsApi.deleteInvite(options);
    },
    onSuccess: (data, variables) => {
      const { organisationId } = variables;
      queryClient.invalidateQueries({
        queryKey: ["organisationInvitations", organisationId],
      });
    },
  });

  const removeUser = useMutation({
    mutationFn: (options: { organisationId: string; userId: string }) => {
      return organisationsApi.removeUser(options);
    },
    onSuccess: (data, variables) => {
      const { organisationId } = variables;
      queryClient.invalidateQueries({
        queryKey: ["organisationMembers", organisationId],
      });
    },
  });

  const createOrganisation = useMutation({
    mutationFn: (organisation: Organisation) => {
      return organisationsApi.create(organisation);
    },
    onSuccess: (data, variables) => {
      const { id } = variables;
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
      queryClient.invalidateQueries({ queryKey: ["permissions"] });
    },
  });

  const deleteOrganisation = useMutation({
    mutationFn: (options: { organisationId: string }) => {
      const { organisationId } = options;
      return organisationsApi.deleteOne(organisationId);
    },
    onSuccess: (data, variables) => {
      const { organisationId } = variables;
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
      queryClient.invalidateQueries({
        queryKey: ["organisation", organisationId],
      });
    },
  });

  return {
    createOrganisation,
    updateOrganisation,
    deleteOrganisation,
    inviteUser,
    resendInvite,
    deleteInvite,
    removeUser,
  };
};

export default useOrganisationMutation;
