import { Category } from "./CategoriesApiTypes";
import { useMutation, useQueryClient } from "react-query";
import useCategoriesApi from "./CategoriesApi";

const useCategoryMutation = () => {
  const categoriesApi = useCategoriesApi();
  const queryClient = useQueryClient();

  const update = useMutation({
    mutationFn: (options: {
      userId?: string;
      teamId?: string;
      organisationId?: string;
      category: Category;
    }) => {
      return categoriesApi.update(options);
    },
    onSuccess: (data, variables) => {
      const { category } = variables;
      const id = category.id;
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      queryClient.invalidateQueries({ queryKey: ["category", id] });
    },
  });

  const create = useMutation({
    mutationFn: (options: {
      userId?: string;
      teamId?: string;
      organisationId?: string;
      category: Category;
    }) => {
      return categoriesApi.create(options);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
    },
  });

  const deleteOne = useMutation({
    mutationFn: (options: {
      userId?: string;
      organisationId?: string;
      teamId?: string;
      projectId: string;
      categoryId: string;
    }) => {
      return categoriesApi.deleteOne(options);
    },
    onSuccess: (data, variables) => {
      const { categoryId } = variables;
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      queryClient.invalidateQueries({ queryKey: ["category", categoryId] });
    },
  });

  return {
    create,
    update,
    deleteOne,
  };
};

export default useCategoryMutation;
