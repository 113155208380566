import { Box, Heading, UnorderedList, ListItem, Text } from "@chakra-ui/react";
import Page from "../../UIKit/Page/Page";
import PageHeader from "../../UIKit/PageHeader/PageHeader";
import Footer from "../../UIKit/Footer/Footer";

const TermsOfServicePage = () => {
    return (
        <>
            <Page sx={{ marginTop: 30, marginBottom: 100 }}>
                <PageHeader sx={{ marginTop: 100, marginBottom: 30 }} title="Terms of Service" />
                <Box>
                    <Text fontSize="lg" mb={4}>
                       These Terms of Service ("Terms") govern your use of our website, applications, and
                        services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these
                        Terms.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Acceptance of Terms
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        By using our Services, you confirm that you have read, understood, and agree to be bound by these Terms. If
                        you do not agree with any part of these Terms, you may not use our Services.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Use of Services
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        You agree to use our Services only for lawful purposes and in a manner that does not infringe the rights of,
                        or restrict or inhibit the use and enjoyment of, our Services by any third party. Prohibited activities
                        include, but are not limited to:
                    </Text>
                    <UnorderedList spacing={3} fontSize="lg">
                        <ListItem>Violating any applicable laws or regulations.</ListItem>
                        <ListItem>Interfering with or disrupting the integrity or performance of our Services.</ListItem>
                        <ListItem>Attempting to gain unauthorized access to our systems or data.</ListItem>
                        <ListItem>Using our Services to distribute spam or malicious software.</ListItem>
                    </UnorderedList>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Intellectual Property
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        All content included in our Services, such as text, graphics, logos, images, and software, is the property
                        of Daybreak or its licensors and is protected by copyright and other intellectual property laws. You may
                        not use any content from our Services without our prior written consent.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Disclaimer of Warranties
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        Our Services are provided "as is" without any warranties of any kind, either express or implied. We do not
                        warrant that our Services will be uninterrupted or error-free, that defects will be corrected, or that our
                        Services are free of viruses or other harmful components.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Limitation of Liability
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        To the fullest extent permitted by law, Daybreak shall not be liable for any indirect, incidental, special,
                        consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or
                        indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our
                        Services.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Changes to These Terms
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        We may update these Terms from time to time. We will notify you of any significant changes. Your continued
                        use of our Services after such changes constitutes your acceptance of the new Terms.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Contact Us
                    </Heading>
                    <Text fontSize="lg">
                        If you have any questions about these Terms, please contact us.
                    </Text>
                </Box>
            </Page>
            <Footer />
        </>
    );
};

export default TermsOfServicePage;