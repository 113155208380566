import {
  Button,
  useDisclosure,
  Badge,
  IconButton,
  VStack,
  HStack,
  Select,
  Flex,
  Text,
  Spinner,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";
import {
  Buildings,
  Check,
  CreditCard,
  EnvelopeSimple,
  EnvelopeSimpleOpen,
  PencilSimple,
  TrashSimple,
  UserCircle,
} from "phosphor-react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import useOrganisationsApi from "../../../../../Api/Resources/Organisations/OrganisationsApi";
import useOrganisationMutation from "../../../../../Api/Resources/Organisations/OrganisationsMutation";
import useTeamsApi from "../../../../../Api/Resources/Teams/TeamsApi";
import { Card, CardHeader } from "../../../../../UIKit/Card/Card";
import { InviteUserModal } from "../Components/InviteUserModal";
import { ModifyOrganisationModal } from "../Components/ModifyOrganisationModal";
import { ModifyTeamModal } from "../Components/ModifyTeamModal";
import { formatDistanceToNow } from "date-fns";
import CustomTable, {
  TableColumn,
  TableRow,
} from "../../../../../UIKit/Table/Table";
import { StandardTableRow } from "../../../../../UIKit/Table/StandardTableRow";
import TitledPage from "../../../../../Components/TitledPage";
import { UserHighlight } from "../../../../../Components/UserHighlight";
import { useEffect, useState } from "react";
import produce from "immer";
import { OrganisationUser } from "../../../../../Api/Resources/Users/UsersApiTypes";
import { useAuthStore } from "../../../../../Store/AuthStore";
import shallow from "zustand/shallow";
import { usePermissions } from "../../../../../Store/PermissionStore";
import { PlansDialog } from "../../../../../UIKit/PlansDialog/PlansDialog";
import useBillingApi from "../../../../../Api/Resources/Billing/BillingApi";
import useBillingMutation from "../../../../../Api/Resources/Billing/BillingMutation";
import { currencies } from "../../../../../constants";

export type OrganisationSettings = {
  currencyCode?: string;
};

const ViewOrganisationPage = () => {
  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const userId = authTokens?.userId;

  const billingApi = useBillingApi();
  const { cancelOrgSubscription, changeOrgSubscription } = useBillingMutation();

  const [isEditingSettings, setIsEditingSettings] = useState(false);
  const [isEditingMembers, setIsEditingMembers] = useState(false);

  const [uneditedMemberData, setUneditedMemberData] = useState<TableRow[]>([]);
  const [membersData, setMembersData] = useState<TableRow[]>([]);

  const [
    uneditedOrganisationSettingsData,
    setUneditedOrganisationSettingsData,
  ] = useState<OrganisationSettings>();
  const [organisationSettingsData, setOrganisationSettingsData] =
    useState<OrganisationSettings>();

  const { getPermission } = usePermissions();

  const { organisationId } = useParams();
  const organisationModal = useDisclosure();
  const inviteUserModal = useDisclosure();
  const teamModal = useDisclosure();

  const organisationsApi = useOrganisationsApi();
  const { updateOrganisation } = useOrganisationMutation();

  const teamsApi = useTeamsApi();
  const { resendInvite, deleteInvite, removeUser } = useOrganisationMutation();

  const { data: organisationPlans, isFetching: isFetchingOrganisationPlans } =
    useQuery(["organisationPlans"], () => {
      return billingApi.listOrganisationPlans();
    });

  const { data: subscription, isFetching: isFetchingSubscription } = useQuery(
    ["orgSubscription", organisationId],
    () => {
      if (organisationId) {
        return billingApi.getOrgSubscription(organisationId);
      }

      return;
    }
  );

  const { data: organisation, isFetching: isFetchingOrganisation } = useQuery(
    ["organisation", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.get(organisationId);
      }

      return;
    }
  );

  const { data: members, isFetching: isFetchingMembers } = useQuery(
    ["organisationMembers", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.listOrganisationMembers(organisationId);
      }

      return;
    }
  );

  const { data: invites, isFetching: isFetchingInvites } = useQuery(
    ["organisationInvitations", organisationId],
    () => {
      if (organisationId) {
        return organisationsApi.listInvites(organisationId);
      }

      return;
    }
  );

  const { data: teams, isFetching: isFetchingTeams } = useQuery(
    ["teams", organisationId],
    () => {
      if (organisationId) {
        return teamsApi.listForOrganisation(organisationId);
      }

      return;
    }
  );

  const onResend = (inviteId: string) => {
    resendInvite.mutate({
      organisationId: organisationId!,
      inviteId,
    });
  };

  const onDeleteInvite = (inviteId: string) => {
    deleteInvite.mutate({
      organisationId: organisationId!,
      inviteId,
    });
  };

  const removeUserFromOrg = (index: number) => {
    const updatedData = produce(membersData, (draft) => {
      draft.splice(index, 1);
    });

    setMembersData(updatedData);
  };

  const onSaveSettings = () => {
    updateOrganisation.mutateAsync({
      ...organisation,
      currencyCode: organisationSettingsData?.currencyCode,
    });

    setIsEditingSettings(false);
  };

  const onSaveMembers = () => {
    // Compare data and uneditedData to work out what items have been removed.
    // Then call removeUser for each of those items.
    const removedItems =
      uneditedMemberData
        .filter((item) => !membersData.find((d) => d.id === item.id))
        .map((item) => item.id as string) ?? [];

    if (removedItems.length > 0) {
      organisationsApi.removeUsers({
        userIds: removedItems,
        organisationId: organisationId!,
      });
    }

    // Compare data and uneditedData to work out what items have been changed.
    const changedItems = membersData
      .map((item) => {
        return item as OrganisationUser;
      })
      .filter((item) => {
        const uneditedItem = uneditedMemberData.find(
          (d) => d.id === item.id
        ) as OrganisationUser;
        return uneditedItem.role !== item.role;
      });


    if (changedItems.length > 0) {
      organisationsApi.updateUsers({
        users: changedItems,
        organisationId: organisationId!,
      });
    }


    setIsEditingMembers(false);
  };

  const onCancelEditMembers = () => {
    setMembersData(uneditedMemberData);
    setIsEditingMembers(false);
  };

  const onCancelEditSettings = () => {
    setOrganisationSettingsData(uneditedOrganisationSettingsData);
    setIsEditingSettings(false);
  };

  useEffect(() => {
    setMembersData(members ?? []);
  }, [members]);

  useEffect(() => {
    if (organisation) {
      setOrganisationSettingsData({
        currencyCode: organisation.currencyCode,
      });
    }
  }, [organisation]);

  const onEditMembers = () => {
    setUneditedMemberData(membersData);
    setIsEditingMembers(true);
  };

  const onEditSettings = () => {
    setUneditedOrganisationSettingsData(organisationSettingsData);
    setIsEditingSettings(true);
  };

  const onRoleChange = (value: string, userId: string) => {
    const updatedData = produce(membersData, (draft) => {
      const user = draft.find((item) => item.id === userId) as OrganisationUser;
      user.role = value;
    });

    setMembersData(updatedData);
  };

  let columns: TableColumn[] = [
    { label: "Name", accessor: "icon" },
    { label: "Last Seen", accessor: "lastSeen" },
    { label: "Role", accessor: "role" },
  ];

  if (isEditingMembers) {
    columns = [
      ...columns,
      { label: "Remove", accessor: "remove", isNumeric: true },
    ];
  }

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <TitledPage
      isLoading={isFetchingOrganisation}
      title={organisation?.name ?? ""}
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
        {
          label: "Organisations",
          link: "/account/organisations",
        },
      ]}
      action={
        getPermission("edit_organisation", organisationId!)
          ? {
            label: "Edit Organisation",
            icon: PencilSimple,
            trigger: organisationModal.onOpen,
          }
          : undefined
      }
      placeholderIcon={Buildings}
      placeholderMessage={"No organisations."}
      showPlaceholder={!organisation}
    >
      <ModifyOrganisationModal
        organisation={organisation}
        isOpen={organisationModal.isOpen}
        onClose={organisationModal.onClose}
      />

      <InviteUserModal
        organisationId={organisation?.id}
        isOpen={inviteUserModal.isOpen}
        onClose={inviteUserModal.onClose}
      />

      <ModifyTeamModal
        organisationId={organisation?.id!}
        isOpen={teamModal.isOpen}
        onClose={teamModal.onClose}
      />

      <VStack
        marginTop={"16px"}
        marginBottom={"64px"}
        spacing={4}
        flexDirection={"column"}
      >
        <Card>
          <CardHeader
            isStandalone={true}
            title="Plan & Billing"
            subtitle={"The plan and billing information for this organisation."}
            action={
              <>
                {getPermission("change_organisation_plan", organisationId!) && (
                  <Flex alignItems={"center"} gap={8}>
                    <Text fontWeight={"medium"} size={"sm"}>
                      {isFetchingSubscription ? (
                        <Spinner />
                      ) : (
                        subscription?.product?.name ?? "No Plan"
                      )}
                    </Text>
                    <PlansDialog
                      onChangePlan={(_: string, priceId: string) => {
                        changeOrgSubscription.mutate({
                          subscriptionId: subscription?.id!,
                          itemId: subscription?.itemId!,
                          priceId,
                          orgId: organisationId!,
                        });
                      }}
                      onCreateSubscription={async (priceId: string) => {
                        if (!organisationId) {
                          throw new Error("User ID not found.");
                        }

                        const result =
                          await billingApi.createOrganisationSubscription({
                            priceId,
                            orgId: organisationId!,
                            orgName: organisation?.name ?? "",
                          });

                        return {
                          clientSecret: result.clientSecret,
                        };
                      }}
                      redirect={`organisations/${organisationId}`}
                      showCancel={!!subscription?.product?.id}
                      type={"organisation"}
                      orgId={organisationId}
                      orgName={organisation?.name}
                      subscriptionId={subscription?.id}
                      highlightPlan={subscription?.product?.tierId ?? "free"}
                      highlightLevel={subscription?.product?.level ?? 0}
                      plans={organisationPlans ?? []}
                      isOpen={isOpen}
                      onClose={onClose}
                      onCancelPlan={() => {
                        const subscriptionId = subscription?.id;

                        if (subscriptionId && organisationId) {
                          cancelOrgSubscription.mutate({
                            subscriptionId,
                            orgId: organisationId,
                          });
                        }
                      }}
                    >
                      <Button
                        disabled={isFetchingSubscription}
                        leftIcon={<CreditCard weight="bold" />}
                        variant={"outline"}
                        colorScheme={"blue"}
                        onClick={onOpen}
                      >
                        Change Plan
                      </Button>
                    </PlansDialog>
                  </Flex>
                )}
              </>
            }
          />
        </Card>
        <Card>
          <CardHeader
            title={`Settings`}
            subtitle={"Manage the settings for this organisation."}
            action={
              !isEditingSettings ? (
                <HStack>
                  {getPermission(
                    "edit_organisation_members",
                    organisationId!
                  ) && (
                      <Button
                        leftIcon={<PencilSimple weight="bold" />}
                        variant={"outline"}
                        colorScheme={"blue"}
                        onClick={onEditSettings}
                      >
                        Edit Settings
                      </Button>
                    )}
                </HStack>
              ) : (
                <HStack>
                  <Button variant={"outline"} onClick={onCancelEditSettings}>
                    Cancel
                  </Button>
                  <Button
                    isLoading={updateOrganisation.isLoading}
                    leftIcon={<Check weight="bold" />}
                    colorScheme={"green"}
                    onClick={onSaveSettings}
                  >
                    Save Settings
                  </Button>
                </HStack>
              )
            }
          />
          <FormControl>
            <FormLabel>Currency</FormLabel>
            <Select
              value={organisationSettingsData?.currencyCode ?? ""}
              onChange={(e) => {
                setOrganisationSettingsData({
                  currencyCode: e.target.value,
                });
              }}
              isDisabled={!isEditingSettings}
              placeholder="Select organisation currency"
            >
              {Object.values(currencies).map((currency) => (
                <option key={currency.name} value={currency.code}>
                  {currency.name} ({currency.code})
                </option>
              ))}
            </Select>
            <FormHelperText opacity={0.5}>
              This will be used throughout your organisation.
            </FormHelperText>
          </FormControl>
        </Card>
        <Card>
          <CardHeader
            title={`Members`}
            subtitle={"View and edit organisation members."}
            action={
              !isEditingMembers ? (
                <HStack>
                  {getPermission(
                    "edit_organisation_members",
                    organisationId!
                  ) && (
                      <Button
                        leftIcon={<PencilSimple weight="bold" />}
                        variant={"outline"}
                        colorScheme={"blue"}
                        onClick={onEditMembers}
                      >
                        Edit Members
                      </Button>
                    )}
                </HStack>
              ) : (
                <HStack>
                  <Button variant={"outline"} onClick={onCancelEditMembers}>
                    Cancel
                  </Button>
                  <Button
                    leftIcon={<Check weight="bold" />}
                    colorScheme={"green"}
                    onClick={onSaveMembers}
                  >
                    Save Members
                  </Button>
                </HStack>
              )
            }
          />

          <CustomTable
            placeholder={{
              label: "No users",
              icon: UserCircle,
            }}
            renderRow={(options) => {
              return <StandardTableRow {...options} />;
            }}
            rows={
              membersData?.map((item, index) => {
                const user = item as OrganisationUser;

                return {
                  icon: <UserHighlight user={user} />,
                  name: user.displayName,
                  lastSeen:
                    user?.lastSeen &&
                    formatDistanceToNow(new Date(user?.lastSeen), {
                      addSuffix: true,
                    }),
                  role: isEditingMembers ? (
                    <Select
                      value={user.role}
                      onChange={(e) => onRoleChange(e.target.value, user.id)}
                      isDisabled={user.id === userId}
                    >
                      <option value="admin">Admin</option>
                      <option value="manager">Manager</option>
                      <option value="user">User</option>
                    </Select>
                  ) : (
                    <Badge px={2} py={1}>
                      {user.role}
                    </Badge>
                  ),
                  remove: (
                    <IconButton
                      onClick={() => removeUserFromOrg(index)}
                      isDisabled={user.id === userId}
                      aria-label="Remove User"
                      icon={<TrashSimple weight="bold" />}
                    />
                  ),
                };
              }) ?? []
            }
            columns={columns}
          />
        </Card>

        {getPermission("invite_organisation_members", organisationId!) && (
          <Card>
            <CardHeader
              title="Invitations"
              subtitle={"View and send new invitations."}
              action={
                <Button
                  variant={"outline"}
                  leftIcon={<EnvelopeSimpleOpen weight="bold" />}
                  colorScheme="blue"
                  onClick={inviteUserModal.onOpen}
                >
                  Send Invite
                </Button>
              }
            />

            <CustomTable
              placeholder={{
                label: "No active invitations",
                icon: EnvelopeSimple,
              }}
              renderRow={(options) => {
                return <StandardTableRow {...options} />;
              }}
              rows={
                invites?.map((invite) => {
                  return {
                    email: invite.email,
                    ignored: invite.ignored ? "Yes" : "No",
                    resend: (
                      <Button
                        onClick={() => onResend(invite.id)}
                        isDisabled={!invite.ignored}
                      >
                        Resend
                      </Button>
                    ),
                    delete: (
                      <Button onClick={() => onDeleteInvite(invite.id)}>
                        Delete
                      </Button>
                    ),
                  };
                }) ?? []
              }
              columns={[
                { label: "Email", accessor: "email" },
                { label: "Ignored", accessor: "ignored" },
                { label: "Resend", accessor: "resend" },
                { label: "Delete", accessor: "delete" },
              ]}
            />
          </Card>
        )}
      </VStack>
    </TitledPage>
  );
};

export default ViewOrganisationPage;
