import useMethods from "../../useMethods";
import {
  BillingTier,
  Subscription,
  SubscriptionRecord,
} from "./BillingApiTypes";

const useBillingApi = () => {
  const methods = useMethods();

  const listIndividualPlans = async (): Promise<BillingTier[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/billing/plans/individual`
    );
    return result.data;
  };

  const listOrganisationPlans = async (): Promise<BillingTier[]> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/billing/plans/organisation`
    );
    return result.data;
  };

  const createOrganisationSubscription = async ({
    priceId,
    orgId,
    orgName,
  }: {
    priceId: string;
    orgId: string;
    orgName: string;
  }): Promise<SubscriptionRecord> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/organisations/${orgId}/billing/subscriptions`,
      { priceId, type: "organisation", orgId, orgName }
    );

    return result.data;
  };

  const createUserSubscription = async ({
    priceId,
    userId,
  }: {
    userId: string;
    priceId: string;
  }): Promise<SubscriptionRecord> => {
    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/users/${userId}/billing/subscriptions`,
      { priceId, type: "individual" }
    );

    return result.data;
  };

  const cancelOrgSubscription = async (
    subscriptionId: string,
    orgId: string
  ): Promise<SubscriptionRecord> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/organisations/${orgId}/subscriptions/${subscriptionId}/cancel`
    );

    return result.data;
  };

  const changeOrgSubscription = async (options: {
    subscriptionId: string;
    itemId: string;
    priceId: string;
    orgId: string;
  }): Promise<SubscriptionRecord> => {
    const { subscriptionId, itemId, orgId, priceId } = options;
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/organisations/${orgId}/subscriptions/${subscriptionId}`,
      { itemId, priceId }
    );

    return result.data;
  };

  const changeUserSubscription = async (options: {
    subscriptionId: string;
    userId: string;
    priceId: string;
    itemId: string;
  }): Promise<SubscriptionRecord> => {
    const { subscriptionId, userId, itemId, priceId } = options;

    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/users/${userId}/subscriptions/${subscriptionId}`,
      { itemId, priceId }
    );

    return result.data;
  };

  const cancelUserSubscription = async (
    subscriptionId: string,
    userId: string
  ): Promise<SubscriptionRecord> => {
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/users/${userId}/subscriptions/${subscriptionId}/cancel`
    );

    return result.data;
  };

  const getUserSubscription = async (userId: string): Promise<Subscription> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/subscription`
    );

    return result.data;
  };

  const getOrgSubscription = async (orgId: string): Promise<Subscription> => {
    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/organisations/${orgId}/subscription`
    );

    return result.data;
  };

  return {
    listIndividualPlans,
    listOrganisationPlans,
    getUserSubscription,
    getOrgSubscription,
    cancelUserSubscription,
    cancelOrgSubscription,
    changeOrgSubscription,
    changeUserSubscription,
    createUserSubscription,
    createOrganisationSubscription,
  };
};

export default useBillingApi;
