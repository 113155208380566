import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import styles from "./SplitWithImage.module.scss";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

const ImageContainer = ({ image, align = "left" }: { image: string, align?: "right" | "left" }) => {
  return (
    <Flex
      style={{ transform: align === "left" ? `rotate(-1deg)` : `rotate(1deg)` }}
      borderWidth="8px"
      borderColor={"transparent"}
      borderRadius={"xl"}
      shadow="base"
    >
      <Image
        rounded={"md"}
        alt={"feature image"}
        src={image}
        objectFit={"cover"}
      />
    </Flex>
  );
};

export type FeatureItem = {
  text: string;
  backgroundColor: string;
  icon: ReactElement;
};

const SplitWithImage = ({
  align = "left",
  title,
  caption,
  image,
}: {
  title: string;
  align?: "left" | "right";
  caption: JSX.Element;
  image: string;
}) => {
  return (
    <Container maxW={"full"} py={16}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {align === "left" && <ImageContainer image={image} />}
        <Stack spacing={4}>
          <Heading fontSize={"42px"} fontWeight={600}>{title}</Heading>
          <Text color={"gray.500"} fontSize={"lg"}>
            {caption}
          </Text>
        </Stack>
        {align === "right" && <ImageContainer align={align} image={image} />}
      </SimpleGrid>
    </Container>
  );
};

export default SplitWithImage;
