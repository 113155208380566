import create from "zustand";
import { devtools } from "zustand/middleware";

export type PageData = {
  title: string;
  subtitle?: string;
  action?: PageAction | PageAction[];
  breadcrumbs?: BreadcrumbDataItem[];
  isLoadingTitle?: boolean;
};

export type PageAction = {
  label?: string;
  helpText?: string;
  trigger: () => void;
  icon?: any;
  variant?: string;
};

export type BreadcrumbDataItem = {
  label: string;
  link?: string;
};

export interface AppState {
  pageData?: PageData;
  setTitle: (title: string) => void;
  setPageData: (pageData: PageData) => void;
}

export const useAppStore = create<AppState>()(
  devtools(
    (set) => ({
      setPageData: (pageData) => set(() => ({ pageData })),
      setTitle: (title) =>
        set((state) => ({
          pageData: {
            ...state.pageData,
            title,
          },
        })),
    }),
    {
      name: "app-state",
    }
  )
);
