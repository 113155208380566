import { useMutation, useQueryClient } from "react-query";
import useTeamsApi from "./TeamsApi";
import {
  AddTeamMemberReqest,
  CreateTeamRequest,
  Team,
  UpdateTeamRequest,
} from "./TeamsApiTypes";

const useTeamsMutation = () => {
  const teamsApi = useTeamsApi();
  const queryClient = useQueryClient();

  const updateTeam = useMutation({
    mutationFn: (options: {
      teamId: string;
      organisationId: string;
      body: UpdateTeamRequest;
    }) => {
      return teamsApi.update(options);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["team", teamId] });
    },
  });

  const createTeam = useMutation({
    mutationFn: (options: {
      organisationId: string;
      body: CreateTeamRequest;
    }) => {
      return teamsApi.create(options);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["permissions"] });
    },
  });

  const addTeamMember = useMutation({
    mutationFn: (options: {
      teamId: string;
      organisationId: string;
      body: AddTeamMemberReqest;
    }) => {
      return teamsApi.addTeamMember(options);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["teams", teamId] });
    },
  });

  const removeTeamMember = useMutation({
    mutationFn: (options: {
      teamId: string;
      organisationId: string;
      userId: string;
    }) => {
      return teamsApi.removeTeamMember(options);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["teams", teamId] });
    },
  });

  const deleteTeam = useMutation({
    mutationFn: (options: { teamId: string; organisationId: string }) => {
      return teamsApi.deleteOne(options);
    },
    onSuccess: (data, variables) => {
      const { teamId } = variables;
      queryClient.invalidateQueries({ queryKey: ["teams"] });
      queryClient.invalidateQueries({ queryKey: ["team", teamId] });
    },
  });

  return {
    createTeam,
    updateTeam,
    deleteTeam,
    addTeamMember,
    removeTeamMember,
  };
};

export default useTeamsMutation;
