import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Box,
  Stack,
  Heading,
  Tr,
  Td,
  Tbody,
  Table,
  Thead,
  Th,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import styles from "./PlansDialog.module.scss";

import { useState } from "react";
import { BillingTier } from "../../Api/Resources/Billing/BillingApiTypes";
import { I18nContext, I18nManager, useI18n } from "@shopify/react-i18n";
import CheckoutPage from "../../Pages/Account/Pages/Checkout/CheckoutPage";

// Define the expected structure for the prop data
interface Plan {
  id: string;
  name: string;
  priceMonthly: string;
  priceYearly: string;
  features: { [key: string]: string };
}

interface PricingTableProps {
  plans: BillingTier[];
  highlightPlan?: string; // name of the plan to highlight
  onSelect: (productId: string, priceId: string) => void;
  highlightLevel?: number;
  loading?: "cancel" | string; // Plan ID of loading
}

const PricingTable = ({
  plans,
  highlightPlan,
  highlightLevel,
  onSelect,
  loading,
}: PricingTableProps) => {
  const [i18n] = useI18n();

  const highlightBg = "var(--chakra-colors-background300)";

  return (
    <Box p={8}>
      <Heading fontWeight={"semibold"} textAlign="center" mb={8}>
        Choose a plan that's right for you
      </Heading>

      {/* Pricing Table */}
      <Box
        overflow={"hidden"}
        borderRadius={"8px"}
        border={"1px solid var(--chakra-colors-border200)"}
      >
        <Table className={styles.table}>
          <Thead>
            <Tr>
              <Th></Th>
              {plans.map((plan) => (
                <Th
                  key={plan.price.id}
                  bg={plan.price.id === highlightPlan ? highlightBg : undefined}
                >
                  {plan.name}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {/* Price Row */}
            <Tr>
              <Td>Price</Td>
              {plans.map((plan) => {
                const value = i18n.formatCurrency(
                  plan.price.unit_amount / 100,
                  {
                    currency: plan.price.currency,
                    form: "short",
                  }
                );

                return (
                  <Td
                    key={plan.name}
                    bg={
                      plan.price.id === highlightPlan ? highlightBg : undefined
                    }
                  >
                    {value} / month
                  </Td>
                );
              })}
            </Tr>

            {/* Buttons Row */}
            <Tr>
              <Td></Td>
              {plans.map((plan) => (
                <Td
                  key={plan.name}
                  bg={plan.price.id === highlightPlan ? highlightBg : undefined}
                >
                  <Button
                    isLoading={loading === plan.price.id}
                    onClick={() => onSelect(plan.id, plan.price.id)}
                    colorScheme={
                      plan.price.id === highlightPlan ? "black" : "blue"
                    }
                    variant={
                      plan.price.id === highlightPlan ? "outline" : "solid"
                    }
                    isDisabled={plan.price.id === highlightPlan}
                  >
                    {plan.price.id === highlightPlan
                      ? "Current plan"
                      : `${plan.level < (highlightLevel ?? 0)
                        ? "Change"
                        : "Upgrade"
                      } to ${plan.name}`}
                  </Button>
                </Td>
              ))}
            </Tr>

            {/* Dynamic Feature Rows */}
            {Object.keys(plans[0].features).map((feature) => (
              <Tr key={feature}>
                <Td>{feature}</Td>
                {plans.map((plan) => (
                  <Td
                    key={plan.name}
                    bg={
                      plan.price.id === highlightPlan ? highlightBg : undefined
                    }
                  >
                    {(plan.features as any)[feature]}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

enum Step {
  Select,
  Payment,
}

export const PlansDialog = ({
  highlightPlan,
  highlightLevel,
  plans,
  children,
  isOpen,
  onClose,
  onCancelPlan,
  type,
  orgId,
  orgName,
  showCancel,
  redirect,
  subscriptionId,
  onChangePlan,
  loading,
  onCreateSubscription,
}: PropsWithChildren<{
  subscriptionId?: string;
  highlightPlan?: string;
  highlightLevel?: number;
  plans: BillingTier[];
  isOpen: boolean;
  onClose: () => void;
  onCancelPlan: () => void;
  onChangePlan: (planId: string, priceId: string) => void;
  type: "individual" | "organisation";
  orgId?: string;
  orgName?: string;
  showCancel?: boolean;
  redirect: string;
  loading?: "cancel" | string;
  onCreateSubscription: (priceId: string) => Promise<{
    clientSecret: string;
  }>;
}>) => {
  const [currentStep, setCurrentStep] = useState(Step.Select);
  const [selectedPlan, setSelectedPlan] = useState<{
    planId: string;
    priceId: string;
  }>();
  const [isLoadingSecret, setIsLoadingSecret] = useState(false);
  const [clientSecret, setClientSecret] = useState<string>();

  const locale = "en";
  const i18nManager = new I18nManager({
    locale,
  });

  return (
    <>
      <I18nContext.Provider value={i18nManager}>
        <Modal
          onClose={() => {
            setCurrentStep(Step.Select);
            onClose();
          }}
          size={"6xl"}
          isOpen={isOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              {currentStep === Step.Select && (
                <PricingTable
                  loading={loading}
                  onSelect={async (planId: string, priceId: string) => {
                    if (planId === "free") {
                      onCancelPlan();
                    } else if (subscriptionId) {
                      // If we are changing an existing subscription...
                      onChangePlan(planId, priceId);
                    } else {
                      // If we are selecting a new plan...
                      setSelectedPlan({
                        planId,
                        priceId,
                      });

                      // Move to the payment step
                      setCurrentStep(Step.Payment);

                      // Create the subscription
                      setIsLoadingSecret(true);
                      const options = await onCreateSubscription(priceId);
                      setClientSecret(options.clientSecret);
                      setIsLoadingSecret(false);
                    }
                  }}
                  highlightLevel={highlightLevel}
                  highlightPlan={highlightPlan}
                  plans={plans}
                />
              )}
              {currentStep === Step.Payment && selectedPlan?.priceId && (
                <Box p={8}>
                  <Heading fontWeight={"semibold"} textAlign="center" mb={8}>
                    Enter your payment information
                  </Heading>
                  <CheckoutPage
                    loading={isLoadingSecret}
                    clientSecret={clientSecret}
                    redirect={redirect}
                    priceId={selectedPlan?.priceId}
                    type={type}
                    orgId={orgId}
                    orgName={orgName}
                  />
                </Box>
              )}

              {showCancel && (
                <Stack>
                  <Button
                    isLoading={loading === "cancel"}
                    onClick={() => {
                      onCancelPlan();
                    }}
                  >
                    Cancel Plan
                  </Button>
                </Stack>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
        {children}
      </I18nContext.Provider>
    </>
  );
};
