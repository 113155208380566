import {
    Button,
    Icon,
    Input,
    VStack,
    Text,
    Box,
} from "@chakra-ui/react";
import { useState } from "react";
import styles from "./AvatarUpload.module.scss";
import useUsersApi from "../../../../../Api/Resources/Users/UsersApi";
import { User } from "../../../../../Api/Resources/Users/UsersApiTypes";
import { Camera } from "phosphor-react";

const AvatarUpload = (props: { user?: User }) => {
    const { user } = props;
    const { uploadProfilePicture } = useUsersApi();

    const [file, setFile] = useState<File | null>(null);
    const [progress, setProgress] = useState<number>(0);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        const userId = user?.id;

        if (file && userId) {
            await uploadProfilePicture(file, userId, (progress) => {
                setProgress(progress);
            });
        } else {
            alert("Please select a file first.");
        }
    };

    return (
        <VStack className={styles.container} spacing={4}>
            <Box
                className={styles.avatarWrapper}
                position="relative"
                onClick={() => document.getElementById("fileInput")?.click()}
            >
                <img
                    className={styles.avatar}
                    src={user?.avatarMedium}
                    alt="User Avatar"
                />
                <Box className={styles.overlay}>
                    <Icon as={Camera} boxSize={8} color="white" />
                </Box>
                <Input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    variant="unstyled"
                    accept="image/*"
                    hidden
                />
            </Box>
            {file && (
                <Button
                    onClick={handleUpload}
                    colorScheme="blue"
                    size="sm"
                    className={styles.uploadButton}
                >
                    Upload
                </Button>
            )}
            {progress > 0 && (
                <VStack width="100%" spacing={2}>
                    <Text fontSize="xs">Upload Progress: {progress}%</Text>
                </VStack>
            )}
        </VStack>
    );
};

export default AvatarUpload;
