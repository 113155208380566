import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export enum Platform {
  Web = "web",
  Mobile = "mobile",
  Menubar = "menubar",
  Desktop = "desktop",
  Mac = "macos",
  Ios = "ios",
}

export interface PlatformState {
  platform: Platform;
  setPlatform: (platform: Platform) => void;
}

export const usePlatformStore = create<PlatformState>()(
  devtools(persist(
    (set) => ({
      setPlatform: (platform) => set(() => ({ platform })),
      platform: Platform.Web,
    }),
    {
      name: "platform-state",
    }
  ))
);
