import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Eye, PencilSimple } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import useProjectsApi from "../../../Api/Resources/Projects/ProjectsApi";
import TitledPage from "../../../Components/TitledPage";
import { ModifyProjectModal } from "../List/Components/ModifyProjectModal";
import OverviewTab from "./Components/OverviewTab";
import CategoriesTab from "./Components/CategoriesTab";
import FinancialTab from "./Components/FinancialTab";
import ApprovalsTab from "./Components/ApprovalsTab";
import { useUserId } from "../../../Store/AuthStore";
import { usePermissions } from "../../../Store/PermissionStore";

const ViewProjectPage = () => {
  const [isProjectModalOpen, setIsProjectModalOpen] = useState<boolean>(false);
  const [requireApproval, setRequireApproval] = useState<boolean>();
  const projectsApi = useProjectsApi();
  const navigate = useNavigate();
  const userId = useUserId();
  const { projectId, teamId, organisationId } = useParams();
  const { getPermission } = usePermissions();

  const hasOverviewPermission = getPermission("get_organisation_project_entries", projectId) || getPermission("get_user_project_entries", projectId);
  const hasFinancialsPermission = getPermission("view_team_project_charge_rates", teamId);

  const { data: project, isFetching: isFetchingProject } = useQuery(
    ["project", projectId],
    () => {
      if (projectId) {
        return projectsApi.get({
          userId,
          projectId,
          teamId,
          organisationId,
        });
      }

      return;
    }
  );

  const hasTeam = useMemo(() => {
    return !!project?.team;
  }, [project]);

  useEffect(() => {
    if (!requireApproval) {
      setRequireApproval(project?.requireApproval ?? false);
    }
  }, [project]);

  const title = useMemo(() => {
    return project?.name ?? "Untitled Project";
  }, [project]);

  const actions = [];

  if (project?.team) {
    actions.push({
      label: "View Team",
      variant: "outline",
      icon: Eye,
      trigger: () =>
        navigate(`/organisations/${organisationId}/teams/${project?.team?.id}`),
    });
  }

  actions.push({
    label: "Edit Project",
    icon: PencilSimple,
    trigger: () => setIsProjectModalOpen(true),
  });

  const showApprovalsTab = hasTeam && project?.requireApproval;

  const tabs = useMemo(() => {
    const items = [];

    if (hasOverviewPermission) {
      items.push("Overview")
    }

    items.push("Categories");

    if (hasFinancialsPermission) {
      items.push("Financials");
    }

    if (showApprovalsTab) {
      items.push("Approvals");
    }

    return items;
  }, [showApprovalsTab, hasOverviewPermission])


  return (
    <TitledPage
      title={title}
      isLoadingTitle={isFetchingProject}
      breadcrumbs={[
        {
          label: "Projects",
          link: "/projects",
        },
      ]}
      action={actions}
    >
      <ModifyProjectModal
        organisation={project?.organisation!}
        team={project?.team!}
        allowTeamSelection={false}
        project={project}
        isOpen={isProjectModalOpen}
        onClose={() => setIsProjectModalOpen(false)}
      />

      <Tabs marginTop={"12px"} isLazy>
        <TabList>
          {tabs.map((tab) => {
            return <Tab>{tab}</Tab>;
          })}
        </TabList>
        <TabPanels>
          {tabs.map((tab) => {
            switch (tab) {
              case "Overview": return (
                <TabPanel paddingX={0}>
                  <OverviewTab
                    hasTeam={hasTeam}
                    subtitle={"View entries for this project."}
                  />
                </TabPanel>
              )
              case "Categories": return (
                <TabPanel paddingX={0}>
                  <CategoriesTab />
                </TabPanel>
              )
              case "Financials": return (
                <TabPanel paddingX={0}>
                  <FinancialTab hasTeam={hasTeam} />
                </TabPanel>
              )
              case "Approvals": return (
                <TabPanel paddingX={0}>
                  <ApprovalsTab />
                </TabPanel>
              )
            }
          })}
        </TabPanels>
      </Tabs>
    </TitledPage>
  );
};

export default ViewProjectPage;
