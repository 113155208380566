import {
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { Bell, BellSimple } from "phosphor-react";
import { useMemo } from "react";
import { useQuery } from "react-query";
import shallow from "zustand/shallow";
import useUsersApi from "../../../Api/Resources/Users/UsersApi";
import useUserMutation from "../../../Api/Resources/Users/UsersMutation";
import { useAuthStore } from "../../../Store/AuthStore";

const NotificationButton = () => {
  const usersApi = useUsersApi();

  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const userId = authTokens?.userId;

  const { data: invites, isFetching: isFetchingInvites } = useQuery(
    ["userInvitations", userId],
    () => {
      if (userId) {
        return usersApi.listInvites(userId);
      }

      return;
    }
  );

  const { acceptOrganisationInvite, ignoreOrganisationInvite } =
    useUserMutation();

  const notificationCount = useMemo(() => {
    return invites?.length ?? 0;
  }, [invites]);

  const onAccept = (inviteId: string) => {
    acceptOrganisationInvite.mutate({
      userId: userId!,
      inviteId,
    });
  };

  const onIgnore = (inviteId: string) => {
    ignoreOrganisationInvite.mutate({
      userId: userId!,
      inviteId,
    });
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Flex position={"relative"}>
          <IconButton
            variant={"ghost"}
            aria-label="Notification Button"
            icon={<Bell size={24} />}
          />
          {notificationCount > 0 && (
            <Flex
              position={"absolute"}
              bottom={0}
              right={-1}
              borderRadius={100}
              fontWeight={"bold"}
              color={"#FFF"}
              fontSize={"12px"}
              justifyContent={"center"}
              alignItems={"center"}
              width={5}
              height={5}
              background={"red"}
            >
              <Text>{notificationCount}</Text>
            </Flex>
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>Notifications</PopoverHeader>
        <PopoverBody>
          {invites && invites?.length > 0 ? (
            invites?.map((invite) => {
              return (
                <Flex>
                  <Flex>Invite to join {invite?.organisation?.name}</Flex>
                  <HStack>
                    <Button onClick={() => onIgnore(invite.id)}>Ignore</Button>
                    <Button onClick={() => onAccept(invite.id)}>Accept</Button>
                  </HStack>
                </Flex>
              );
            })
          ) : (
            <Flex
              paddingY={10}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <BellSimple color="var(--chakra-colors-border200)" size={60} />
              <Text marginTop={"16px"} color="var(--chakra-colors-border200)">
                No notifications
              </Text>
            </Flex>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationButton;
