import useMethods from "../../useMethods";
import { ChargeRate, ChargeRateType } from "../Projects/ProjectsApiTypes";

export type ChargeRateCreateBody = {
  items: {
    value: string;
    type: ChargeRateType;
    userId: string;
    projectId: string;
  }[];
};

const useChargeRatesApi = () => {
  const methods = useMethods();

  const listForProjects = async (options: {
    userId?: string;
    organisationId?: string;
    teamId?: string;
    projectId: string;
  }): Promise<ChargeRate[]> => {
    const { organisationId, teamId, projectId, userId } = options;

    if (organisationId && teamId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/charge-rates`
      );
      return result.data;
    }

    if (userId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/charge-rates`
      );
      return result.data;
    }

    throw new Error("Organisation ID or User ID is required");
  };

  const upsert = async (options: {
    organisationId?: string;
    userId?: string;
    teamId?: string;
    projectId: string;
    body: ChargeRateCreateBody;
  }): Promise<ChargeRate[]> => {
    const { organisationId, teamId, projectId, body, userId } = options;

    if (organisationId && teamId) {
      const result = await methods.post(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/charge-rates/bulk`,
        body
      );
      return result.data;
    }

    if (userId) {
      const result = await methods.post(
        `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/charge-rates/bulk`,
        body
      );
      return result.data;
    }

    throw new Error("Organisation ID or User ID is required");
  };

  return {
    listForProjects,
    upsert,
  };
};

export default useChargeRatesApi;
