import { Box, Heading, UnorderedList, ListItem, Text } from "@chakra-ui/react";
import Page from "../../UIKit/Page/Page";
import PageHeader from "../../UIKit/PageHeader/PageHeader";
import Footer from "../../UIKit/Footer/Footer";

const AboutUsPage = () => {
    return (
        <>
            <Page sx={{ marginTop: 30, marginBottom: 100 }}>
                <PageHeader sx={{ marginTop: 100, marginBottom: 30 }} title="About Us" />
                <Box>

                    <Text fontSize="lg" mb={4}>
                        At Daybreak, we believe that time is your most valuable resource and the foundation of effective teamwork. Our
                        mission is to empower individuals and teams to work more cohesively, stay aligned, and remain in sync, creating
                        a harmonious and productive environment.
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        Founded with a vision to build tools that enhance collaboration and alignment, Daybreak is more than just a
                        platform—it’s the first step towards transforming how teams work together. Starting with intuitive time
                        tracking, we aim to create solutions that foster connection and clarity in every aspect of teamwork.
                    </Text>
                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Why Daybreak?
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        We understand the challenges of modern collaboration. That’s why Daybreak is designed to simplify and streamline
                        your time tracking process as a foundation for better teamwork. Our software is:
                    </Text>
                    <UnorderedList spacing={3} fontSize="lg">
                        <ListItem>
                            <Text as="span" fontWeight="bold">
                                User-Friendly:
                            </Text>{" "}
                            Intuitive design ensures you spend less time tracking and more time focusing on collective goals.
                        </ListItem>
                        <ListItem>
                            <Text as="span" fontWeight="bold">
                                Versatile:
                            </Text>{" "}
                            Tailored for both individuals and teams, our tools adapt to diverse workflows and collaboration styles.
                        </ListItem>
                        <ListItem>
                            <Text as="span" fontWeight="bold">
                                Insightful:
                            </Text>{" "}
                            With powerful analytics and reporting, you gain a deeper understanding of how time is spent and how to stay
                            aligned.
                        </ListItem>
                    </UnorderedList>
                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Our Values
                    </Heading>
                    <UnorderedList spacing={3} fontSize="lg">
                        <ListItem>
                            <Text as="span" fontWeight="bold">
                                Teamwork:
                            </Text>{" "}
                            We prioritize features that enhance collaboration and bring teams closer together.
                        </ListItem>
                        <ListItem>
                            <Text as="span" fontWeight="bold">
                                Alignment:
                            </Text>{" "}
                            Helping teams stay on the same page with clear, actionable insights.
                        </ListItem>
                        <ListItem>
                            <Text as="span" fontWeight="bold">
                                Transparency:
                            </Text>{" "}
                            Empowering users with tools to understand and optimize time management.
                        </ListItem>
                        <ListItem>
                            <Text as="span" fontWeight="bold">
                                Innovation:
                            </Text>{" "}
                            Continuously evolving to meet the needs of modern teams in an ever-changing world.
                        </ListItem>
                    </UnorderedList>
                </Box>
            </Page>
            <Footer />
        </>
    )
}

export default AboutUsPage;