import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { GraphData } from '../../Api/Resources/Analysis/AnalysisApiTypes';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BurndownChart = (props: GraphData) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Budget Remaining',
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Months',
                },
            },
        },
    };

    return <Line data={props} options={options} />;
};

export default BurndownChart;