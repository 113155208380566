import useMethods from "../../useMethods";
import {
  AddTeamMemberReqest,
  CreateTeamRequest,
  Team,
  TeamMember,
} from "./TeamsApiTypes";

const useTeamsApi = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const methods = useMethods();

  const get = async (options: {
    teamId: string;
    userId?: string;
    organisationId?: string;
  }): Promise<Team> => {
    const { teamId, organisationId, userId } = options;

    const url = organisationId
      ? `${baseUrl}/organisations/${organisationId}/teams/${teamId}`
      : `${baseUrl}/users/${userId}/teams/${teamId}`;

    const result = await methods.get(url);
    return result.data;
  };

  const listTeamMembers = async (options: {
    organisationId?: string;
    teamId: string;
    search?: string;
  }): Promise<TeamMember[]> => {
    const { organisationId, teamId } = options;

    const result = await methods.get(
      `${baseUrl}/organisations/${organisationId}/teams/${teamId}/members${options.search ? `?search=${options.search}` : ``}`
    );

    return result.data;
  };

  const listForOrganisation = async (
    organisationId: string,
    search?: string
  ): Promise<Team[]> => {
    const result = await methods.get(
      `${baseUrl}/organisations/${organisationId}/teams${search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listForOrganisationAndUser = async (
    organisationId: string,
    userId: string,
    search?: string
  ): Promise<Team[]> => {
    const result = await methods.get(
      `${baseUrl}/organisations/${organisationId}/users/${userId}/teams${search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listForUser = async (userId: string): Promise<Team[]> => {
    const result = await methods.get(`${baseUrl}/users/${userId}/teams`);
    return result.data;
  };

  const create = async (options: {
    organisationId: string;
    body: CreateTeamRequest;
  }): Promise<Team> => {
    const { organisationId, body } = options;

    const result = await methods.post(
      `${baseUrl}/organisations/${organisationId}/teams`,
      body
    );
    return result.data;
  };

  const addTeamMember = async (options: {
    teamId: string;
    organisationId: string;
    body: AddTeamMemberReqest;
  }): Promise<Team> => {
    const { teamId, organisationId, body } = options;
    const { userId, role } = body;

    const result = await methods.post(
      `${baseUrl}/organisations/${organisationId}/teams/${teamId}/members`,
      {
        userId,
        role,
      }
    );
    return result.data;
  };

  const removeTeamMember = async (options: {
    teamId: string;
    organisationId: string;
    userId: string;
  }): Promise<Team> => {
    const { teamId, organisationId, userId } = options;

    console.log(options)

    const result = await methods.deleteOne(
      `${baseUrl}/organisations/${organisationId}/teams/${teamId}/members/${userId}`
    );

    return result.data;
  };

  const update = async (options: {
    teamId: string;
    organisationId: string;
    body: Team;
  }): Promise<Team> => {
    const { teamId, organisationId, body } = options;
    const result = await methods.patch(
      `${baseUrl}/organisations/${organisationId}/teams/${teamId}`,
      body
    );
    return result.data;
  };

  const deleteOne = async (options: {
    teamId: string;
    organisationId: string;
  }): Promise<Team> => {
    const { teamId, organisationId } = options;

    const result = await methods.deleteOne(
      `${baseUrl}/organisations/${organisationId}/teams/${teamId}`
    );
    return result.data;
  };

  return {
    listForOrganisation,
    listForOrganisationAndUser,
    listForUser,
    create,
    update,
    deleteOne,
    get,
    addTeamMember,
    removeTeamMember,
    listTeamMembers,
  };
};

export default useTeamsApi;
