import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Flex } from "@chakra-ui/react";
import { GraphData } from "../../Api/Resources/Analysis/AnalysisApiTypes";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props: GraphData) => {
  return (
    <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
      <Doughnut
        options={{
          plugins: {
            legend: {
              display: false,
              position: "bottom",
              labels: {
                padding: 36,
              },
            },
          },
        }}
        data={props}
      />
    </Flex>
  );
};

export default DoughnutChart;
