import { Flex } from "@chakra-ui/react";
import styles from "./PageHeader.module.scss";

const PageHeader = ({
  sx,
  title,
}: {
  sx?: any;
  title: string;
  subtitle?: string;
  isLoading?: boolean;
}) => {
  return (
    <Flex sx={sx} flexDirection={"row"}>
      <Flex className={`${styles.title}`}>{title}</Flex>
    </Flex>
  );
};

export default PageHeader;
