import React from "react";
import axios, { AxiosResponse } from "axios";
import { useToast } from "@chakra-ui/react";

export const useAuthInterceptor = function ({
  isLoggedIn,
}: {
  isLoggedIn: boolean;
}) {
  const toast = useToast();

  React.useEffect(() => {
    const authInterceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const showToast = (id: string, title: string, description: string) => {
          if (!toast.isActive(id)) {
            toast({
              id,
              title,
              description,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        };

        if (error.response.status === 401) {
          const data = error?.response?.data?.message;

          if (data && isLoggedIn) {
            showToast("401", data.title, data.message);
          }
        }

        if (error.response.status === 400) {
          const data = error?.response?.data?.message;

          if (data.message) {
            showToast("400", "Error", data.message);
          }
        }

        if (error.response.status === 403) {
          const data = error.response.data.message;

          if (data) {
            showToast("403", data.title, data.message);
          }
        }

        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(authInterceptor);
    };
  }, [isLoggedIn]);
};
