import WhatsNewCarousel from "./WhatsNewCarousel";

const WhatsNewPage = () => {
    return (
        <div>
            <WhatsNewCarousel />
        </div>
    );
};

export default WhatsNewPage;
