import { Button, useDisclosure } from "@chakra-ui/react";
import { Plus, UsersFour } from "phosphor-react";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import useTeamsApi from "../../../Api/Resources/Teams/TeamsApi";
import TitledPage from "../../../Components/TitledPage";
import { useAuthStore } from "../../../Store/AuthStore";
import ListContainer, { Group, ListGroup } from "../../../UIKit/List/List";
import {
  ListItemHeader,
  ItemType,
  Item,
} from "../../../UIKit/ListItem/ListItem";
import { ModifyTeamModal } from "../../Account/Pages/Organisations/Components/ModifyTeamModal";
import { TeamListItem } from "./Components/TeamListItem/TeamListItem";
import { groupBy } from "../../../Utilities/GroupBy";
import { Organisation } from "../../../Api/Resources/Organisations/OrganisationsApiTypes";

const ListTeamsPage = () => {
  const navigate = useNavigate();
  const teamsApi = useTeamsApi();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { authTokens } = useAuthStore(
    (state) => ({
      authTokens: state.authTokens,
    }),
    shallow
  );

  const { data: _teams, isFetching: isFetchingTeams } = useQuery(
    ["teams"],
    () => {
      return teamsApi.listForUser(authTokens?.userId!);
    }
  );

  const onItemSelected = (link: string) => {
    navigate(link);
  };

  const groups = useMemo(() => {
    const DEFAULT_ORG_NAME = "My Teams";
    const DEFAULT_ORG_ID = "my_teams";

    const result: Group[] = [];
    const groupedTeams = groupBy(
      _teams ?? [],
      (item) => item.organisation?.id,
      DEFAULT_ORG_ID
    );

    Object.keys(groupedTeams).map((groupKey) => {
      const teams = groupedTeams[groupKey];

      let team: Organisation | undefined;
      if (teams.length > 0) {
        team = teams[0].organisation;
      }

      const items: Item[] = groupedTeams[groupKey].map((team) => {
        return {
          id: team.id!,
          type: ItemType.Item,
          label: team.name ?? DEFAULT_ORG_NAME,
          description: team.description,
          data: team,
          link: `/organisations/${team.organisation?.id}/teams/${team.id}`,
        };
      });

      result.push({
        title: team?.name ?? DEFAULT_ORG_NAME,
        id: team?.id ?? DEFAULT_ORG_ID,
        items,
      });
    });

    return result;
  }, [_teams]);

  return (
    <TitledPage
      showPlaceholder={_teams?.length === 0}
      placeholderMessage={"No teams"}
      placeholderIcon={UsersFour}
      isLoading={isFetchingTeams}
      title={"Teams"}
      isResponsive={false}
      action={{
        label: "New Team",
        icon: Plus,
        trigger: onOpen,
      }}
    >
      <ModifyTeamModal isOpen={isOpen} onClose={onClose} />

      <ListContainer>
        <ListGroup
          onClick={(id) => onItemSelected(id)}
          groups={groups}
          listItem={TeamListItem}
        />
      </ListContainer>
    </TitledPage>
  );
};

export default ListTeamsPage;
