import useMethods from "../../useMethods";
import {
  ApproveRequest,
  Entry,
  EntryMetaData,
  EntryRequest,
  UpdateEntryTimerRequest,
} from "./EntriesApiTypes";

export type EntryQueryStringOptions = {
  startDate?: string;
  endDate?: string;
  organisationId?: string;
  userId?: string;
  projectId?: string;
  teamId?: string;
  projects?: string[];
  categories?: string[];
  members?: string[];
  approved?: boolean;
};

const useEntriesApi = () => {
  const methods = useMethods();

  const createQueryString = (options: EntryQueryStringOptions) => {
    const { startDate, endDate } = options;
    const dates =
      startDate && endDate
        ? `startDate=${startDate}&endDate=${endDate}`
        : undefined;
    const projects =
      (options.projects ?? []).length > 0
        ? `projects=${(options.projects ?? []).join(",")}`
        : undefined;
    const categories =
      (options.categories ?? []).length > 0
        ? `categories=${(options.categories ?? []).join(",")}`
        : undefined;
    const members =
      (options.members ?? []).length > 0
        ? `members=${(options.members ?? []).join(",")}`
        : undefined;
    const approved =
      options.approved !== undefined
        ? `approved=${options.approved}`
        : undefined;

    let queryString = "";
    if (dates || projects || categories || members || approved) {
      queryString = `?${[dates, projects, categories, members, approved]
        .filter((x) => x)
        .join("&")}`;
    }

    return queryString;
  };

  const listForUser = async (
    options: {
      userId: string;
    } & EntryQueryStringOptions
  ): Promise<Entry[]> => {
    const { userId } = options;
    const queryString = createQueryString(options);

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/entries${queryString}`
    );
    return result.data;
  };

  const listForProject = async (
    options: EntryQueryStringOptions
  ): Promise<{
    count: number;
    data: Entry[];
  }> => {
    const { organisationId, projectId, teamId, userId } = options;
    const queryString = createQueryString(options);

    if (organisationId && teamId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/entries${queryString}`
      );

      return result.data;
    }

    if (userId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/entries${queryString}`
      );

      return result.data;
    }

    throw new Error("Invalid options");
  };

  const listForTeam = async (
    options: EntryQueryStringOptions
  ): Promise<{
    count: number;
    data: Entry[];
  }> => {
    const { teamId, organisationId } = options;
    const queryString = createQueryString(options);

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/entries${queryString}`
    );

    return result.data;
  };

  const getMetaData = async (options: {
    userId: string;
    startDate: string;
    endDate: string;
  }): Promise<EntryMetaData> => {
    const { userId, startDate, endDate } = options;

    const result = await methods.get(
      `${process.env.REACT_APP_API_URL}/users/${userId}/entries/metadata?startDate=${startDate}&endDate=${endDate}`
    );
    return result.data;
  };

  const create = async ({
    body,
    userId,
    organisationId,
    teamId,
    projectId,
  }: {
    userId?: string;
    organisationId?: string;
    projectId?: string;
    teamId?: string;
    body: EntryRequest;
  }): Promise<Entry> => {
    if (organisationId && teamId) {
      const result = await methods.post(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/entries`,
        body
      );

      return result.data;
    }

    if (userId && projectId) {
      const result = await methods.post(
        `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/entries`,
        body
      );

      return result.data;
    }

    if (userId) {
      const result = await methods.post(
        `${process.env.REACT_APP_API_URL}/users/${userId}/entries`,
        body
      );

      return result.data;
    }

    throw new Error("Invalid options");
  };

  const update = async ({
    body,
    userId,
    organisationId,
    teamId,
  }: {
    userId?: string;
    organisationId?: string;
    teamId?: string;
    body: EntryRequest;
  }): Promise<Entry> => {
    if (organisationId && teamId) {
      const result = await methods.patch(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/entries/${body.id}`,
        body
      );
      return result.data;
    }

    if (userId) {
      const result = await methods.patch(
        `${process.env.REACT_APP_API_URL}/users/${userId}/entries/${body.id}`,
        body
      );
      return result.data;
    }

    throw new Error("Invalid options");
  };

  const approve = async ({
    body,
    organisationId,
    teamId,
  }: {
    organisationId?: string;
    teamId?: string;
    body: ApproveRequest;
  }): Promise<Entry> => {
    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/entries/${body.entryId}/approve`,
      { approved: body.approved }
    );
    return result.data;
  };

  const deleteOne = async ({
    organisationId,
    teamId,
    userId,
    entryId,
  }: {
    organisationId?: string;
    teamId?: string;
    userId?: string;
    entryId: string;
  }): Promise<Entry> => {
    if (organisationId && teamId) {
      const result = await methods.deleteOne(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/entries/${entryId}`
      );
      return result.data;
    }

    if (userId) {
      const result = await methods.deleteOne(
        `${process.env.REACT_APP_API_URL}/users/${userId}/entries/${entryId}`
      );
      return result.data;
    }

    throw new Error("Invalid options");
  };

  const startTimer = async ({
    userId,
    organisationId,
    teamId,
    body,
  }: {
    userId?: string;
    organisationId?: string;
    teamId?: string;
    body: UpdateEntryTimerRequest;
  }): Promise<Entry> => {
    if (organisationId && teamId) {
      const result = await methods.patch(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/entries/${body.id}/start`,
        body
      );
      return result.data;
    }

    if (userId) {
      const result = await methods.patch(
        `${process.env.REACT_APP_API_URL}/users/${userId}/entries/${body.id}/start`,
        body
      );
      return result.data;
    }

    throw new Error("Invalid options");
  };

  const stopTimer = async ({
    userId,
    organisationId,
    teamId,
    body,
  }: {
    userId?: string;
    organisationId?: string;
    teamId?: string;
    body: UpdateEntryTimerRequest;
  }): Promise<Entry> => {
    if (organisationId && teamId) {
      const result = await methods.patch(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/entries/${body.id}/stop`,
        body
      );
      return result.data;
    }

    if (userId) {
      const result = await methods.patch(
        `${process.env.REACT_APP_API_URL}/users/${userId}/entries/${body.id}/stop`,
        body
      );
      return result.data;
    }

    throw new Error("Invalid options");
  };

  return {
    getMetaData,
    create,
    update,
    startTimer,
    stopTimer,
    deleteOne,
    listForProject,
    listForTeam,
    approve,
    listForUser,
  };
};

export default useEntriesApi;
