import { Box, Flex } from "@chakra-ui/react";
import { CaretRight } from "phosphor-react";
import { Project } from "../../../../../Api/Resources/Projects/ProjectsApiTypes";
import ResponsiveContainer from "../../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import styles from "./ProjectsListItem.module.scss";
import { Item } from "../../../../../UIKit/ListItem/ListItem";

export const ProjectsListItem = ({
  item,
  onClick,
}: {
  item: Item;
  onClick?: (link: string) => void;
}) => {
  const project: Project = item.data;
  const numCategories = project.categories?.length ?? 0;

  return (
    <Flex
      onClick={() => onClick && item.link && onClick(item.link)}
      className={styles.listItem}
    >
      <ResponsiveContainer>
        <Flex alignItems={"center"}>
          <Box className={styles.indicator} sx={{ backgroundColor: project.color }} />
          <Flex className={styles.container}>
            <Flex className={styles.content}>
              <Flex className={styles.name}>{item.label}</Flex>
              <Flex className={styles.description}>{item.description}</Flex>
              <Flex className={styles.color}>{numCategories} {numCategories === 1 ? "Category" : "Categories"}</Flex>
            </Flex>
          </Flex>
          <CaretRight weight="bold" size={24} />
        </Flex>
      </ResponsiveContainer>
    </Flex>
  );
};
