import { Box, Flex } from "@chakra-ui/react";
import styles from "./DateBar.module.scss";
import { CaretCircleLeft, CaretCircleRight, Play } from "phosphor-react";
import { useEffect, useMemo } from "react";
import { addDays, format, isEqual, startOfDay, startOfWeek } from "date-fns";
import ResponsiveContainer from "../ResponsiveContainer/ResponsiveContainer";
import {
  Entry,
  EntryTotals,
} from "../../Api/Resources/Entries/EntriesApiTypes";
import { formatTime } from "../../Utilities/TimeUtility";
import { parseISO } from "date-fns";
import { useEntryStore } from "../../Store/EntryStore";
import shallow from "zustand/shallow";
import { useSettingsStore } from "../../Store/SettingsStore";
import { Platform, usePlatformStore } from "../../Store/PlatformStore";

const getDates = (startDate: Date, numDates: number): Date[] => {
  var dateArray = new Array();
  var currentDate = startDate;
  for (let i = 0; i < numDates; i++) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

const DateBar = ({
  selectedDate,
  onDateChange,
  totals = {},
  timedEntry,
  isMinified,
}: {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  totals?: EntryTotals;
  timedEntry?: Entry;
  isMinified?: boolean;
}) => {
  const { platform } = usePlatformStore(
    (state) => ({ platform: state.platform }),
    shallow
  );

  const isMobile = useMemo(() => {
    return platform === Platform.Mobile;
  }, [platform]);

  const { additionalTime } = useEntryStore(
    (state) => ({
      additionalTime: state.additionalTime,
    }),
    shallow
  );

  const { showSeconds } = useSettingsStore(
    (state) => ({
      showSeconds: state.showSeconds,
    }),
    shallow
  );

  const today = new Date();

  const dates = useMemo(() => {
    return getDates(startOfWeek(selectedDate), 7);
  }, [selectedDate]);

  useEffect(() => {
    onDateChange(selectedDate);
  }, [selectedDate]);

  return (
    <Flex className={styles.container}>
      <Flex className={styles.barContainer}>
        <ResponsiveContainer padding={0}>
          <Flex className={styles.bar}>
            <Flex
              zIndex={100}
              onClick={() => onDateChange(addDays(selectedDate, -1))}
              className={`${styles.arrow} ${styles.left}`}
            >
              <CaretCircleLeft
                color={"var(--chakra-colors-background500)"}
                weight="fill"
                className={styles.caret}
              />
            </Flex>
            {dates.map((date, index) => {
              const display = format(date, isMinified ? "EEE" : "EEE d");
              const displayDate = format(startOfDay(date), "yyyy-MM-dd");
              const total = totals[displayDate];

              const isActive = timedEntry
                ? isEqual(
                  startOfDay(parseISO(timedEntry.date)),
                  startOfDay(date)
                )
                : false;

              const isSelected = isEqual(
                startOfDay(selectedDate),
                startOfDay(date)
              );

              return (
                <Flex
                  key={index}
                  className={styles.dateContainer}
                  width={"100%"}
                  position={"relative"}
                >
                  <Flex
                    onClick={() => onDateChange(date)}
                    className={`${styles.date} ${isSelected ? styles.selected : ""
                      } ${isEqual(startOfDay(date), startOfDay(today))
                        ? styles.today
                        : ""
                      }`}
                  >
                    <Flex
                      className={`${styles.day} ${isEqual(startOfDay(date), startOfDay(today))
                          ? styles.today
                          : ""
                        }`}
                    >
                      {display}
                    </Flex>
                    <Flex
                      color={isActive ? "orange" : ""}
                      className={styles.time}
                    >
                      {isActive && (
                        <Box marginRight={"4px"}>
                          <Play color={"orange"} size={14} weight="bold" />
                        </Box>
                      )}

                      {formatTime(
                        total + (isActive ? additionalTime : 0),
                        showSeconds
                      )}
                    </Flex>
                  </Flex>
                  <Flex
                    className={`${styles.indicator} ${isSelected ? styles.selected : ""
                      } ${isActive && isSelected ? styles.active : ""}`}
                  />
                </Flex>
              );
            })}
            <Flex
              onClick={() => onDateChange(addDays(selectedDate, 1))}
              className={`${styles.arrow} ${styles.right}`}
            >
              <CaretCircleRight
                color={"var(--chakra-colors-background500)"}
                weight="fill"
                className={styles.caret}
              />
            </Flex>
          </Flex>
        </ResponsiveContainer>
      </Flex>
    </Flex>
  );
};

export default DateBar;
