import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import IconLight from "../../Assets/icon.png";
import IconDark from "../../Assets/icon_dark.png";
import ResponsiveContainer from "../ResponsiveContainer/ResponsiveContainer";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { colorMode } = useColorMode();

  return (
    <Box
      bg={"var(--chakra-colors-background300)"}
      color={useColorModeValue("gray.700", "gray.200")}
      borderTop={"1px solid var(--chakra-colors-border100)"}
      bgGradient={useColorModeValue(
        "linear(to-b, white, gray.100)",
        "linear(to-b, gray.800, gray.900)"
      )}
      mt={16}
      borderColor={useColorModeValue("gray.200", "gray.700")}
    >
      <ResponsiveContainer>
        <Flex justifyContent={"space-between"} pt={"40px"} pb={"100px"} width={"100%"}>
          <Flex flexDirection={"column"}>
            <Box>
              <img
                src={colorMode === "light" ? IconLight : IconDark}
                style={{ height: "32px", marginBottom: "16px" }}
              />
            </Box>
            <Text fontSize={"sm"}>© {new Date().getFullYear()} Daybreak. All rights reserved</Text>
          </Flex>
          <Flex>
            <Flex gap={32}>
              <Stack align={"flex-start"}>
                <ListHeader>Company</ListHeader>
                <Link href={"/about-us"}>About us</Link>
                <Link href={"/contact-us"}>Contact us</Link>
              </Stack>
              <Stack align={"flex-start"}>
                <ListHeader>Support</ListHeader>
                <Link href={"/privacy-policy"}>Privacy Policy</Link>
                <Link href={"/terms-of-service"}>Terms of Service</Link>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </ResponsiveContainer>
    </Box>
  );
}
