import { useMemo } from "react";
import useOrganisationsApi from "../Api/Resources/Organisations/OrganisationsApi";
import { useParams } from "react-router-dom";
import { currencies } from "../constants";
import { useUserId } from "../Store/AuthStore";
import { useQuery } from "react-query";
import useUsersApi from "../Api/Resources/Users/UsersApi";

export const useCurrency = () => {
  const organisationsApi = useOrganisationsApi();
  const userId = useUserId();
  const usersApi = useUsersApi();
  const { organisationId } = useParams();

  const { data: organisation } = useQuery({
    queryKey: ["organisation", organisationId],
    queryFn: () => {
      if (organisationId) {
        return organisationsApi.get(organisationId);
      }

      return;
    },
    enabled: organisationId !== undefined,
  });

  const { data: user } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => {
      if (userId) {
        return usersApi.get(userId);
      }
    },
  });

  const currency = useMemo(() => {
    if (organisation?.currencyCode) {
      return (currencies as any)[organisation.currencyCode];
    }

    if (user?.currencyCode) {
      return (currencies as any)[user.currencyCode];
    }

    return currencies.USD;
  }, [organisation, user]);

  return currency;
};
