import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TextInput } from "../../../../../UIKit/TextInput/TextInput";
import { User } from "../../../../../Api/Resources/Users/UsersApiTypes";
import useUserMutation from "../../../../../Api/Resources/Users/UsersMutation";
import AvatarUpload from "./AvatarUpload";

export const ModifyPersonalDetails = ({
    isOpen,
    onClose,
    user,
}: {
    isOpen: boolean;
    onClose: () => void;
    user?: User,
}) => {
    const { update } = useUserMutation();

    const [givenName, setGivenName] = useState<string>();
    const [familyName, setFamilyName] = useState<string>();

    const onPrepareModalClose = () => {
        setGivenName(undefined);
        setFamilyName(undefined);
        onClose();
    };

    const onModifyPersonalDetails = async () => {
        if (user) {
            await update.mutateAsync({
                userId: user.id,
                user: {
                    givenName,
                    familyName,
                },
            });

            onPrepareModalClose();
        }
    }

    useEffect(() => {
        if (user) {
            setGivenName(user.givenName);
            setFamilyName(user.familyName);
        }
    }, [user, isOpen]);

    return (
        <>
            <Modal
                size={"lg"}
                isCentered
                isOpen={isOpen}
                onClose={onPrepareModalClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Update Profile
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AvatarUpload user={user} />
                        <Stack width={"100%"} spacing={4}>
                            <TextInput
                                value={givenName}
                                onChange={(e: any) => setGivenName(e.target.value)}
                                placeholder="Given Name"
                                label="Name"
                                id="name"
                            />
                            <TextInput
                                value={familyName}
                                onChange={(e: any) => setFamilyName(e.target.value)}
                                placeholder="Last Name"
                                label="Name"
                                id="name"
                            />
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Flex width={"100%"} justifyContent={"space-between"}>
                            <Button variant="ghost" mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button
                                colorScheme="blue"
                                isLoading={update.isLoading}
                                onClick={onModifyPersonalDetails}
                            >
                                Update Profile
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
