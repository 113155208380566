import { Flex, FlexProps } from "@chakra-ui/react";
import { Item, ItemType, ListItemHeader } from "../ListItem/ListItem";
import { Fragment } from "react";

const ListContainer = (
  props: FlexProps & {
    children: JSX.Element | JSX.Element[];
  }
) => {
  return (
    <Flex {...props} flexDirection={"column"}>
      {props.children}
    </Flex>
  );
};

export type Group = {
  id: string;
  title: string;
  items: Item[];
};

export const ListGroup = ({
  groups,
  listItem,
  onClick,
}: {
  groups: Group[];
  listItem: any;
  onClick: (link: string) => void;
}) => {
  const ListItem = listItem;

  return (
    <>
      {groups.map((group) => {
        return (
          <Fragment key={group.id}>
            <ListItemHeader
              item={{
                type: ItemType.Header,
                label: group.title,
                id: group.id,
              }}
            />
            {group.items.map((item) => {
              return <ListItem key={item.id} onClick={onClick} item={item} />;
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default ListContainer;
