import { useMutation, useQueryClient } from "react-query";
import useBillingApi from "./BillingApi";

const useBillingMutation = () => {
  const teamsApi = useBillingApi();
  const queryClient = useQueryClient();

  const createOrganisationSubscription = useMutation({
    mutationFn: (options: {
      priceId: string;
      orgId: string;
      orgName: string;
    }) => {
      return teamsApi.createOrganisationSubscription(options);
    },
    onSuccess: (data, variables) => {
      const { orgId } = variables;

      queryClient.invalidateQueries({
        queryKey: ["orgSubscription", orgId],
      });
    },
  });

  const createUserSubscription = useMutation({
    mutationFn: (options: { priceId: string; userId: string }) => {
      return teamsApi.createUserSubscription(options);
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;

      queryClient.invalidateQueries({
        queryKey: ["userSubscription", userId],
      });
    },
  });

  const cancelUserSubscription = useMutation({
    mutationFn: (options: { subscriptionId: string; userId: string }) => {
      const { subscriptionId, userId } = options;
      return teamsApi.cancelUserSubscription(subscriptionId, userId);
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries({ queryKey: ["userSubscription", userId] });
    },
  });

  const cancelOrgSubscription = useMutation({
    mutationFn: (options: { subscriptionId: string; orgId: string }) => {
      const { orgId, subscriptionId } = options;
      return teamsApi.cancelOrgSubscription(subscriptionId, orgId);
    },
    onSuccess: (data, variables) => {
      const { orgId } = variables;
      queryClient.invalidateQueries({ queryKey: ["orgSubscription", orgId] });
    },
  });

  const changeOrgSubscription = useMutation({
    mutationFn: (options: {
      subscriptionId: string;
      orgId: string;
      itemId: string;
      priceId: string;
    }) => {
      const { orgId, subscriptionId, itemId, priceId } = options;
      return teamsApi.changeOrgSubscription({
        subscriptionId,
        orgId,
        priceId,
        itemId,
      });
    },
    onSuccess: (data, variables) => {
      const { orgId } = variables;
      queryClient.invalidateQueries({ queryKey: ["orgSubscription", orgId] });
    },
  });

  const changeUserSubscription = useMutation({
    mutationFn: (options: {
      subscriptionId: string;
      userId: string;
      itemId: string;
      priceId: string;
    }) => {
      const { userId, subscriptionId, itemId, priceId } = options;
      return teamsApi.changeUserSubscription({
        subscriptionId,
        userId,
        priceId,
        itemId,
      });
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries({ queryKey: ["userSubscription", userId] });
    },
  });

  return {
    cancelUserSubscription,
    cancelOrgSubscription,
    changeOrgSubscription,
    changeUserSubscription,
    createUserSubscription,
    createOrganisationSubscription,
  };
};

export default useBillingMutation;
