import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import useOrganisationMutation from "../../../../../Api/Resources/Organisations/OrganisationsMutation";
import { TextInput } from "../../../../../UIKit/TextInput/TextInput";

export const InviteUserModal = ({
  isOpen,
  onClose,
  organisationId,
}: {
  isOpen: boolean;
  onClose: () => void;
  organisationId?: string;
}) => {
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<string>("user");

  const { inviteUser } = useOrganisationMutation();

  const onInviteUser = () => {
    if (organisationId && email) {
      inviteUser.mutate({
        organisationId,
        email,
        role,
      });

      onPrepareModalClose();
    } else {
      throw new Error("No organisation or email provided.");
    }
  };

  const onPrepareModalClose = () => {
    setEmail(undefined);
    onClose();
  };

  return (
    <>
      <Modal
        size={"lg"}
        isCentered
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width={"100%"} spacing={4}>
              <TextInput
                value={email}
                type="email"
                onChange={(e: any) => setEmail(e.target.value)}
                placeholder="Email"
                label="Email"
                id="email"
              />
              <Select
                value={role}
                onChange={(e: any) => {
                  setRole(e.target.value);
                }}
              >
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
                <option value="user">User</option>
              </Select>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Box />
              <Flex>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button onClick={onInviteUser} colorScheme="blue">
                  Invite User
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
