import { useQuery } from "react-query";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  Td,
  VStack,
} from "@chakra-ui/react";
import { Check, PencilSimple, Placeholder } from "phosphor-react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { useParams } from "react-router-dom";
import CustomTable, {
  ColumnType,
  TableRow,
} from "../../../../UIKit/Table/Table";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { useEffect, useMemo, useState } from "react";
import {
  ChargeRate,
  ChargeRateType,
} from "../../../../Api/Resources/Projects/ProjectsApiTypes";
import produce from "immer";
import useChargeRatesApi, {
  ChargeRateCreateBody,
} from "../../../../Api/Resources/ChargeRates/ChargeRatesApi";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import { groupBy } from "../../../../Utilities/GroupBy";
import { first } from "../../../../Utilities/First";
import { UserTableRow } from "../../../../UIKit/Table/UserTableRow";
import { useUserId } from "../../../../Store/AuthStore";
import useUsersApi from "../../../../Api/Resources/Users/UsersApi";
import Loading from "../../../../UIKit/Loading/Loading";
import { useCurrency } from "../../../../Hooks/useCurrency";

const FinancialTab = ({ hasTeam }: { hasTeam: boolean }) => {
  const [isEditingChargeRates, setIsEditingChargeRates] = useState(false);
  const [isEditingBudget, setIsEditingBudget] = useState(false);

  const currency = useCurrency();
  const { projectId, teamId, organisationId } = useParams();
  const chargeRatesApi = useChargeRatesApi();
  const projectsApi = useProjectsApi();
  const userId = useUserId();
  const usersApi = useUsersApi();

  const [uneditedBudget, setUneditedBudget] = useState<string>();

  const [uneditedChargeRatesData, setUneditedChargeRatesData] = useState<
    TableRow[]
  >([]);

  const [chargeRateData, setChargeRateDate] = useState<TableRow[]>([]);
  const [budgetData, setBudgetData] = useState<string>();

  const { data: fetchedUsers, isFetching: isFetchingMembers } = useQuery({
    queryKey: ["users", projectId],
    queryFn: () => {
      if (projectId) {
        return projectsApi.listUsers({
          projectId,
          teamId,
          organisationId,
        });
      }

      return;
    },
    enabled: hasTeam, // Only fetch users if the project has a team.
  });

  const { data: fetchedUser, isFetching: isFetchingUser } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => {
      if (userId) {
        return usersApi.get(userId);
      }
    },
    enabled: !hasTeam,
  });

  const users = useMemo(() => {
    if (hasTeam) {
      return fetchedUsers;
    } else {
      if (fetchedUser) {
        return [fetchedUser];
      }
    }
  }, [fetchedUsers, fetchedUser, hasTeam]);

  const { data: chargeRates, isFetching: isFetchingChargeRates } = useQuery(
    ["chargeRates", projectId],
    () => {
      if (projectId) {
        return chargeRatesApi.listForProjects({
          userId,
          projectId,
          teamId,
          organisationId,
        });
      }

      return;
    }
  );

  const { data: project, isFetching: isFetchingProject } = useQuery(
    ["project", projectId],
    () => {
      if (projectId) {
        return projectsApi.get({
          userId,
          projectId,
          teamId,
          organisationId,
        });
      }

      return;
    }
  );

  useEffect(() => {
    const budget = project?.budget;
    setBudgetData(budget?.toString() ?? "");
  }, [project]);

  useEffect(() => {
    if (users && chargeRates) {
      const chargeRatesByUser = groupBy(
        chargeRates,
        (item) => item.userId,
        "default"
      );

      const data = users.map((user) => {
        const chargeRate = first(chargeRatesByUser[user.id]);

        return {
          userId: user.id,
          id: chargeRate?.id,
          user,
          chargeRate,
        };
      });

      setChargeRateDate(data);
    }
  }, [chargeRates, users]);

  const onChange = (value: string, rowIndex: number) => {
    const updatedData = produce(chargeRateData, (draft) => {
      const item = draft[rowIndex].chargeRate as ChargeRate;

      draft[rowIndex].chargeRate = {
        ...item,
        value,
        type: ChargeRateType.Hour,
      };
    });

    setChargeRateDate(updatedData);
  };

  const onSaveBudget = async () => {
    // Upsert the charge rates...
    if (project) {
      if (budgetData) {
        await projectsApi.update({
          userId,
          project: {
            ...project,
            budget: parseFloat(budgetData),
          },
        });
      }

      setIsEditingBudget(false);
    } else {
      throw new Error("Project not found");
    }
  };

  const onSaveChargeRates = async () => {
    // Create the submission of charge rates...
    const body: ChargeRateCreateBody = {
      items: [],
    };

    if (!projectId) {
      throw new Error("Project ID is required.");
    }

    chargeRateData.forEach((item) => {
      const chargeRate = item.chargeRate as ChargeRate;
      const userId = item.userId as string;

      if (chargeRate) {
        body.items.push({
          ...chargeRate,
          projectId,
          userId,
        });
      }
    });

    // Upsert the charge rates...
    await chargeRatesApi.upsert({
      organisationId,
      userId,
      teamId,
      projectId,
      body,
    });

    setIsEditingChargeRates(false);
  };

  if (
    isFetchingMembers ||
    isFetchingChargeRates ||
    isFetchingProject ||
    isFetchingUser
  ) {
    return <Loading />;
  }

  return (
    <VStack spacing={4}>
      <Card isPadded={false}>
        <CardHeader
          title="Budget"
          subtitle="Set the budget for this project."
          action={
            !isEditingBudget ? (
              <HStack>
                <Button
                  leftIcon={<PencilSimple weight="bold" />}
                  variant={"outline"}
                  colorScheme={"blue"}
                  onClick={() => {
                    setUneditedBudget(budgetData);
                    setIsEditingBudget(true);
                  }}
                >
                  Edit Budget
                </Button>
              </HStack>
            ) : (
              <HStack>
                <Button
                  variant={"outline"}
                  onClick={() => {
                    setIsEditingBudget(false);
                    setBudgetData(uneditedBudget);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  leftIcon={<Check weight="bold" />}
                  colorScheme={"green"}
                  onClick={onSaveBudget}
                >
                  Save Budget
                </Button>
              </HStack>
            )
          }
        />
        <FormControl>
          <FormLabel htmlFor="email">Project Budget</FormLabel>
          <InputGroup>
            <InputLeftAddon>{currency.symbol}</InputLeftAddon>
            <Input
              value={budgetData}
              onChange={(e) => {
                setBudgetData(e.target.value);
              }}
              disabled={!isEditingBudget}
              placeholder="Enter project budget..."
            />
          </InputGroup>
        </FormControl>
      </Card>
      <Card isPadded={false}>
        <CardHeader
          title="Charge Rates"
          subtitle={
            hasTeam
              ? "Manage charge rates for members of this project."
              : "Manage your charge rate for this project."
          }
          action={
            !isEditingChargeRates ? (
              <HStack>
                <Button
                  leftIcon={<PencilSimple weight="bold" />}
                  variant={"outline"}
                  colorScheme={"blue"}
                  onClick={() => {
                    setUneditedChargeRatesData(chargeRateData);
                    setIsEditingChargeRates(true);
                  }}
                >
                  Edit Charge Rates
                </Button>
              </HStack>
            ) : (
              <HStack>
                <Button
                  variant={"outline"}
                  onClick={() => {
                    setChargeRateDate(uneditedChargeRatesData);
                    setIsEditingChargeRates(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  leftIcon={<Check weight="bold" />}
                  colorScheme={"green"}
                  onClick={onSaveChargeRates}
                >
                  Save Charge Rates
                </Button>
              </HStack>
            )
          }
        />

        <CustomTable
          isLoading={isFetchingChargeRates}
          placeholder={{
            label: "No billing",
            icon: Placeholder,
          }}
          renderRow={(options) => {
            const {
              row,
              column: { type, isDisabled, accessor },
              rowIndex,
              totalRows,
            } = options;

            switch (type) {
              case ColumnType.INPUT: {
                const item = row[accessor] as {
                  value: string;
                  type: ChargeRateType;
                };

                return (
                  <Td borderBottom={rowIndex === totalRows - 1 ? 0 : undefined}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="gray.300"
                        fontSize="1.2em"
                        children={currency.symbol}
                      />
                      <Input
                        onChange={(e) => onChange(e.target.value, rowIndex)}
                        isDisabled={!isEditingChargeRates || isDisabled}
                        value={item?.value}
                      />
                      <InputRightAddon children={item?.type ?? "hour"} />
                    </InputGroup>
                  </Td>
                );
              }
              case ColumnType.USER: {
                return <UserTableRow {...options} />;
              }
              default: {
                return <StandardTableRow {...options} />;
              }
            }
          }}
          rows={chargeRateData}
          columns={[
            { label: "Name", accessor: "user", type: ColumnType.USER },
            {
              label: "Project Rate",
              accessor: "chargeRate",
              type: ColumnType.INPUT,
            },
          ]}
        />
      </Card>
    </VStack>
  );
};

export default FinancialTab;
