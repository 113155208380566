import {
  Avatar,
  AvatarGroup,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { CheckCircle, CircleWavy, Export, Placeholder } from "phosphor-react";
import { RangeDatePicker } from "../../../../UIKit/DatePicker/RangeDatePicker";
import CustomTable from "../../../../UIKit/Table/Table";
import { format, parseISO, startOfDay } from "date-fns";
import { formatTime } from "../../../../Utilities/TimeUtility";
import DoughnutChart from "../../../../UIKit/Graphs/DoghnutChart";
import { useMemo, useState } from "react";
import useAnalysisApi from "../../../../Api/Resources/Analysis/AnalysisApi";
import { MultiValue, PropsValue } from "react-select";
import { useParams } from "react-router-dom";
import useCategoriesApi from "../../../../Api/Resources/Categories/CategoriesApi";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";
import useEntriesApi from "../../../../Api/Resources/Entries/EntriesApi";
import { useQuery } from "react-query";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { Team } from "../../../../Api/Resources/Teams/TeamsApiTypes";
import useTeamsApi from "../../../../Api/Resources/Teams/TeamsApi";
import { useUserId } from "../../../../Store/AuthStore";

export interface TeamEntriesTabProps {
  selectedDates?: Date[];
  controls?: JSX.Element[] | JSX.Element;
  team?: Team;
}

const TeamEntriesTab = (props: TeamEntriesTabProps) => {
  const { team } = props;
  const userId = useUserId();
  const { teamId, organisationId } = useParams();
  const [selectedDates, setSelectedDates] = useState<Date[]>();
  const [selectedFilterCategory, setSelectedFilterCategory] =
    useState<MultiValue<{ value: string; label: string }> | null>();

  const [selectedFilterProject, setSelectedFilterProject] =
    useState<MultiValue<{ value: string; label: string }> | null>();

  const [selectedFilterMember, setSelectedFilterMember] = useState<MultiValue<{
    value: string;
    label: string;
  }> | null>();
  const segments = [
    { label: "Table", value: "table" },
    { label: "Graph", value: "graph" },
  ];

  const [selectedApproval, setSelectedApproval] =
    useState<PropsValue<{ label: string; value: string }>>(null);

  const [selectedSegment, setSelectedSegment] = useState<string>(
    segments[0].value
  );

  const analysisApi = useAnalysisApi();
  const categoriesApi = useCategoriesApi();
  const projectsApi = useProjectsApi();
  const entriesApi = useEntriesApi();
  const teamsApi = useTeamsApi();

  const onExport = () => {
    let startDate: string | undefined;
    let endDate: string | undefined;

    if (selectedDates?.length === 2) {
      startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
      endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
    }

    return analysisApi.exportData({
      startDate,
      endDate,
      categories:
        selectedFilterCategory?.map((category) => category.value) ?? [],
      projects: [],
      members: selectedFilterMember?.map((member) => member.value) ?? [],
      keys: [],
      organisationId,
      teamId,
      userId,
    });
  };

  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    [
      "teamEntries",
      teamId,
      selectedDates,
      selectedFilterCategory,
      selectedFilterMember,
      selectedFilterProject,
    ],
    () => {
      if (teamId) {
        let startDate: string | undefined;
        let endDate: string | undefined;

        if (selectedDates?.length === 2) {
          startDate = format(startOfDay(selectedDates[0]), "yyyy-MM-dd");
          endDate = format(startOfDay(selectedDates[1]), "yyyy-MM-dd");
        }

        return entriesApi.listForTeam({
          organisationId,
          userId,
          teamId: team?.id,
          startDate,
          endDate,
          projects:
            selectedFilterProject?.map((project) => project.value) ?? [],
          categories:
            selectedFilterCategory?.map((category) => category.value) ?? [],
          members: selectedFilterMember?.map((member) => member.value) ?? [],
        });
      }

      return;
    }
  );

  const loadCategories = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    categoriesApi
      .listAll({
        userId,
        organisationId,
        teamId,
        projectIds:
          selectedFilterProject?.map((project) => project.value) ?? [],
        search: inputValue,
      })
      .then((categories) => {
        const result = categories.map((category) => {
          return {
            label: category.name ?? "Untitled Category",
            value: category.id!,
          };
        });

        callback(result);
      });
  };

  const loadMembers = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    if (teamId) {
      teamsApi
        .listTeamMembers({ teamId, organisationId, search: inputValue })
        .then((users) => {
          const result = users.map((user) => {
            return {
              label: user.displayName ?? "Unknown User",
              value: user.id!,
            };
          });

          callback(result);
        });
    }
  };

  const loadProjects = () => {
    if (teamId) {
      return projectsApi.list({ teamId, organisationId }).then((projects) => {
        const result = projects.map((project) => {
          return {
            label: project.name ?? "Untitled Project",
            value: project.id!,
          };
        });

        return result;
      });
    }
  };

  const rows = useMemo(() => {
    return (
      entries?.data?.map((entry) => {
        return {
          description: entry.description,
          category: entry.category?.name,
          date: format(parseISO(entry.date), "yyyy-MM-dd"),
          time: formatTime(entry.time),
          users: (
            <AvatarGroup size="sm" max={2}>
              {entry?.users?.map((user) => {
                return <Avatar name={user.displayName} />;
              })}
            </AvatarGroup>
          ),
          approved: entry.approved ? <CheckCircle /> : <CircleWavy />,
        };
      }) ?? []
    );
  }, [entries]);

  let tableColumns = [
    { label: "Description", accessor: "description" },
    { label: "Category", accessor: "category" },
    { label: "Date", accessor: "date" },
    { label: "Time", accessor: "time" },
    { label: "Users", accessor: "users" },
  ];

  return (
    <VStack spacing={4}>
      <Card isPadded={false}>
        <CardHeader
          title="Entries"
          subtitle={"View entries for this team."}
          action={
            <HStack>
              <Button
                leftIcon={<Export weight="bold" />}
                variant={"outline"}
                colorScheme={"blue"}
                onClick={onExport}
              >
                Export
              </Button>
            </HStack>
          }
        />
        <HStack spacing={4} justifyContent={"space-evenly"}>
          <FormControl>
            <FormLabel htmlFor="dateRange">Date Range</FormLabel>
            <RangeDatePicker
              name="dateRange"
              usePortal
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
            />
          </FormControl>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Projects</FormLabel>
              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Projects"
                value={selectedFilterProject}
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterProject(null);
                    setSelectedFilterCategory(null);
                  }

                  setSelectedFilterProject(value);
                }}
                loadOptions={loadProjects}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Categories</FormLabel>

              <AsyncSelect
                key={selectedFilterProject
                  ?.map((project) => project.value)
                  .join(",")}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "100%",
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Categories"
                value={selectedFilterCategory}
                isDisabled={
                  !selectedFilterProject || selectedFilterProject.length === 0
                }
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterCategory(null);
                  }

                  setSelectedFilterCategory(value);
                }}
                loadOptions={loadCategories}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
          <Flex width={"100%"}>
            <FormControl>
              <FormLabel htmlFor="dateRange">Members</FormLabel>

              <AsyncSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: "var(--chakra-colors-chakra-border-color)",
                    backgroundColor: "var(--chakra-colors-background100)",
                  }),
                }}
                isClearable
                isMulti
                placeholder="Select Members"
                value={selectedFilterMember}
                onChange={(value) => {
                  if (value.length === 0) {
                    setSelectedFilterMember(null);
                  }

                  setSelectedFilterMember(value);
                }}
                loadOptions={loadMembers}
                defaultOptions={true}
              />
            </FormControl>
          </Flex>
        </HStack>
      </Card>
      <Card>
        {isFetchingEntries ? (
          <Spinner />
        ) : (
          <CustomTable
            placeholder={{
              label: "No entries",
              icon: Placeholder,
            }}
            renderRow={(options) => {
              return <StandardTableRow {...options} />;
            }}
            rows={rows}
            columns={tableColumns}
          />
        )}
      </Card>
    </VStack>
  );
};

export default TeamEntriesTab;
