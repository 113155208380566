import useMethods from "../../useMethods";
import { SendMessageRequest } from "./ContactApiTypes";

const useContactApi = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const methods = useMethods();

    const sendMessage = async (options: {
        body: SendMessageRequest;
    }): Promise<boolean> => {
        const result = await methods.post(
            `${baseUrl}/contact`,
            options.body
        );
        
        return result.data;
    };

    return {
        sendMessage
    };
};

export default useContactApi;
