import create from "zustand";
import useUsersApi from "../Api/Resources/Users/UsersApi";
import { useEffect } from "react";
import shallow from "zustand/shallow";
import { useUserId } from "./AuthStore";
import { useQuery } from "react-query";

export type UserPermissions = {
  [key: string]: {
    description: string;
    value: {
      [key: string]: {
        enabled: boolean;
        message: string;
      };
    };
  };
};

export interface PermissionState {
  permissions: UserPermissions;
  setPermissions: (permissions: UserPermissions) => void;
}

export const usePermissionStore = create<PermissionState>((set, get) => ({
  permissions: {},
  setPermissions: async (permissions: UserPermissions) => {
    set({ permissions });
  },
}));

export const usePermissions = () => {
  const { permissions } = usePermissionStore(
    (state) => ({
      permissions: state.permissions,
    }),
    shallow
  );

  const getPermission = (flag: string, resourceId?: string): boolean => {
    if (!resourceId) {
      return false;
    }

    return permissions[flag]?.value[resourceId]?.enabled ?? false;
  };

  const getPermissionMessage = (flag: string, resourceId?: string) => {
    if (!resourceId) {
      return "";
    }

    return permissions[flag]?.value[resourceId]?.message ?? "";
  };

  return { getPermission, getPermissionMessage };
};

export const useFetchPermissions = () => {
  const usersApi = useUsersApi();
  const userId = useUserId();

  const { setPermissions } = usePermissionStore(
    (state) => ({ setPermissions: state.setPermissions }),
    shallow
  );

  const { data: permissions, isFetching: isFetchingPermissions } =
    useQuery(["permissions", userId], () => {
      if (userId) {
        return usersApi.getPermissions(userId);
      }
    });

  useEffect(() => {
    setPermissions(permissions ?? {});
  }, [permissions]);
};
