import {
  CreateChargeRateRequest,
  CreateProjectRequest,
  Project,
  UpdateProjectRequest,
} from "./ProjectsApiTypes";
import ProjectsApi from "./ProjectsApi";
import { useMutation, useQueryClient } from "react-query";

const useProjectMutation = () => {
  const projectsApi = ProjectsApi();
  const queryClient = useQueryClient();

  const updateProject = useMutation({
    mutationFn: (options: {
      userId: string;
      project: UpdateProjectRequest;
    }) => {
      return projectsApi.update(options);
    },
    onSuccess: (data, variables) => {
      const {
        project: { id },
      } = variables;
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({ queryKey: ["project", id] });
    },
  });

  const createProject = useMutation({
    mutationFn: (options: {
      userId: string;
      project: CreateProjectRequest;
    }) => {
      return projectsApi.create(options);
    },
    onSuccess: (data, variables) => {
      const {
        project: { teamId },
      } = variables;

      queryClient.invalidateQueries({ queryKey: ["projects"] });

      if (teamId) {
        queryClient.invalidateQueries({ queryKey: ["teamProjects", teamId] });
      }
    },
  });

  const deleteProject = useMutation({
    mutationFn: (options: { project: Project; userId: string }) => {
      return projectsApi.deleteOne({
        projectId: options.project.id!,
        organisationId: options.project.organisation?.id,
        teamId: options.project.team?.id,
        userId: options.userId,
      });
    },
    onSuccess: (data, variables) => {
      const { project } = variables;
      const projectId = project.id;
      const teamId = project.team?.id;

      queryClient.invalidateQueries({ queryKey: ["projects"] });
      queryClient.invalidateQueries({ queryKey: ["project", projectId] });

      if (teamId) {
        queryClient.invalidateQueries({ queryKey: ["teamProjects", teamId] });
        queryClient.invalidateQueries({ queryKey: ["teams", teamId] });
      }
    },
  });

  return {
    createProject,
    updateProject,
    deleteProject,
  };
};

export default useProjectMutation;
