import { Badge, Button, useDisclosure } from "@chakra-ui/react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { Placeholder, Plus, TrashSimple } from "phosphor-react";
import CustomTable, {
  ColumnType,
  TableColumn,
} from "../../../../UIKit/Table/Table";
import { ButtonTableRow } from "../../../../UIKit/Table/ButtonTableRow";
import useTeamsMutation from "../../../../Api/Resources/Teams/TeamsMutation";
import { AvatarTableRow } from "../../../../UIKit/Table/AvatarTableRow";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { useMemo } from "react";
import { Team } from "../../../../Api/Resources/Teams/TeamsApiTypes";
import { AddTeamMemberModal } from "./AddTeamMemberModal";
import { UserTableRow } from "../../../../UIKit/Table/UserTableRow";
import useTeamsApi from "../../../../Api/Resources/Teams/TeamsApi";
import { useQuery } from "react-query";
import { usePermissions } from "../../../../Store/PermissionStore";
import { useParams } from "react-router-dom";

export const TeamMembersTab = ({ team }: { team?: Team }) => {
  const teamsApi = useTeamsApi();
  const addMemberModal = useDisclosure();
  const { removeTeamMember } = useTeamsMutation();
  const { getPermission } = usePermissions();
  const { teamId, organisationId } = useParams();

  const { data: teamMembers, isFetching: isFetchingTeamMembers } = useQuery(
    ["teamMembers", teamId],
    () => {
      if (teamId && organisationId) {
        return teamsApi.listTeamMembers({
          teamId,
          organisationId,
        });
      }
    }
  );

  const onRemoveTeamMember = (userId: string) => {
    if (!teamId || !organisationId) {
      throw new Error("Team ID or Organisation ID is missing");
    }

    removeTeamMember.mutate({
      teamId,
      organisationId,
      userId,
    });
  };

  const rows = useMemo(() => {
    return (
      teamMembers?.map((member) => {
        return {
          role: (
            <Badge px={2} py={1}>
              {member.role}
            </Badge>
          ),
          user: member,
        };
      }) ?? []
    );
  }, [teamMembers]);

  const columns = useMemo(() => {
    const columns: TableColumn[] = [
      { label: "Name", accessor: "user", type: ColumnType.USER },
      { label: "Team Role", accessor: "role" },
    ];

    if (getPermission("remove_team_member", teamId!)) {
      columns.push({
        label: "Remove",
        accessor: "remove",
        isNumeric: true,
        type: ColumnType.BUTTON,
      });
    }

    return columns;
  }, [getPermission, teamId]);

  return (
    <>
      <AddTeamMemberModal
        organisationId={team?.organisation?.id}
        teamId={team?.id}
        isOpen={addMemberModal.isOpen}
        onClose={addMemberModal.onClose}
      />

      <Card isPadded={false}>
        <CardHeader
          title="Members"
          subtitle="View, edit and remove team members."
          action={
            getPermission("add_team_member", teamId!) ? (
              <Button
                leftIcon={<Plus weight="bold" />}
                variant={"outline"}
                colorScheme={"blue"}
                onClick={addMemberModal.onOpen}
              >
                Add Member
              </Button>
            ) : undefined
          }
        />

        <CustomTable
          isLoading={isFetchingTeamMembers}
          placeholder={{
            label: "No team members",
            icon: Placeholder,
          }}
          renderRow={(options) => {
            const { column } = options;

            switch (column.type) {
              case ColumnType.BUTTON: {
                return (
                  <ButtonTableRow
                    icon={TrashSimple}
                    onItemClicked={({ rowIndex }) => {
                      if (!team?.members) return;

                      const member = team?.members[rowIndex];

                      if (member?.id) {
                        onRemoveTeamMember(member.id);
                      }
                    }}
                    {...options}
                  />
                );
              }
              case ColumnType.AVATAR: {
                return <AvatarTableRow {...options} />;
              }
              case ColumnType.USER: {
                return <UserTableRow {...options} />;
              }
              default: {
                return <StandardTableRow {...options} />;
              }
            }
          }}
          rows={rows}
          columns={columns}
        />
      </Card>
    </>
  );
};
