import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import IconLight from "../../Assets/icon.png";
import IconDark from "../../Assets/icon_dark.png";
import { useEffect } from "react";
import useUserMutation from "../../Api/Resources/Users/UsersMutation";

export const VerifyEmailPage = () => {
  const { colorMode } = useColorMode();
  const { verifyEmail, resendVerificationEmail } = useUserMutation();
  const location = useLocation();

  // Extract the token from the query parameters
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const email = query.get("email");

  useEffect(() => {
    if (token && email) {
      verifyEmail.mutate({ token, email });
    }
  }, [token, email]);

  if (verifyEmail.isError) {
    return (
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack spacing={6} align={"center"} maxW={"md"} textAlign={"center"}>
          <Heading fontSize={"2xl"}>Failed to Verify Email</Heading>
          <Text color={"gray.500"}>
            Your email verification link has expired or is invalid. Please
            request a new verification link.
          </Text>
          <Flex gap={4}>
            <Button
              variant={"outline"}
              as={NavLink}
              to="/login"
              colorScheme={"blue"}
            >
              Go to Login
            </Button>

            {email && (
              <Button
                as={NavLink}
                to="/resend-email-verification"
                colorScheme={"blue"}
                onClick={() => {
                  resendVerificationEmail.mutate(email);
                }}
              >
                Resend Verification Link
              </Button>
            )}
          </Flex>
        </Stack>
      </Flex>
    );
  }

  if (verifyEmail.isSuccess) {
    return (
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack spacing={6} align={"center"} maxW={"md"} textAlign={"center"}>
          <img
            src={colorMode === "light" ? IconLight : IconDark}
            style={{ height: "42px", marginBottom: "16px" }}
            alt="App Icon"
          />
          <Heading fontSize={"2xl"}>Email Verified Successfully</Heading>
          <Text color={"gray.500"}>
            Your email has been verified. You can now log in to your account.
          </Text>
          <Button as={NavLink} to="/login" colorScheme={"blue"}>
            Go to Login
          </Button>
        </Stack>
      </Flex>
    );
  }

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack spacing={6} align={"center"} maxW={"md"} textAlign={"center"}>
        <Heading fontSize={"2xl"}>Verifying Email...</Heading>
      </Stack>
    </Flex>
  );
};

export default VerifyEmailPage;
