import { useMutation, useQueryClient } from "react-query";
import useUsersApi from "./UsersApi";
import { User } from "./UsersApiTypes";

const useUserMutation = () => {
  const usersApi = useUsersApi();
  const queryClient = useQueryClient();

  const acceptOrganisationInvite = useMutation({
    mutationFn: (options: { inviteId: string; userId: string }) => {
      return usersApi.acceptInvite(options);
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries({ queryKey: ["userInvitations", userId] });
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
    },
  });

  const ignoreOrganisationInvite = useMutation({
    mutationFn: (options: { inviteId: string; userId: string }) => {
      return usersApi.ignoreInvite(options);
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries({ queryKey: ["userInvitations", userId] });
    },
  });

  const verifyEmail = useMutation({
    mutationFn: (options: { token: string; email: string }) => {
      return usersApi.verifyEmail(options);
    },
  });

  const resendVerificationEmail = useMutation({
    mutationFn: (email: string) => {
      return usersApi.resendVerificationEmail(email);
    },
  });

  const update = useMutation({
    mutationFn: (options: { userId: string; user: Partial<User> }) => {
      return usersApi.update(options);
    },
    onSuccess: (data, variables) => {
      const { userId } = variables;
      queryClient.invalidateQueries({ queryKey: ["user", userId] });
    },
  });

  return {
    ignoreOrganisationInvite,
    acceptOrganisationInvite,
    verifyEmail,
    resendVerificationEmail,
    update,
  };
};

export default useUserMutation;
