import useMethods from "../../useMethods";
import { Category } from "./CategoriesApiTypes";

type ListAllCategoriesOptions = {
  projectIds: string[];
  search?: string;
  userId?: string;
  teamId?: string;
  organisationId?: string;
};

const useCategoriesApi = () => {
  const methods = useMethods();

  const list = (options: {
    projectId: string;
    userId?: string;
    search?: string;
    teamId?: string ;
    organisationId?: string;
  }): Promise<Category[]> => {
    const { userId, organisationId, teamId, search = "", projectId } = options;

    if (teamId && organisationId) {
      return listForTeamAndProject({
        projectId,
        search,
        teamId,
        organisationId,
      });
    } else if (userId) {
      return listForUserAndProject({ projectId, search, userId });
    }

    throw new Error("Invalid options provided");
  };

  const listForUserAndProject = async (options: {
    userId: string;
    projectId: string;
    search: string;
  }): Promise<Category[]> => {
    const { userId, projectId, search } = options;

    const result = await methods.get(
      `${
        process.env.REACT_APP_API_URL
      }/users/${userId}/projects/${projectId}/categories${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listForTeamAndProject = async (options: {
    projectId: string;
    search?: string;
    teamId: string;
    organisationId: string;
  }): Promise<Category[]> => {
    const { projectId, search, teamId, organisationId } = options;
    const result = await methods.get(
      `${
        process.env.REACT_APP_API_URL
      }/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/categories${
        search ? `?search=${search}` : ``
      }`
    );
    return result.data;
  };

  const listAll = async (
    options: ListAllCategoriesOptions
  ): Promise<Category[]> => {
    const { projectIds, search = "", userId, teamId, organisationId } = options;
    const params = new URLSearchParams();

    if (projectIds.length > 0) {
      params.append("projects", projectIds.join(","));
    }

    if (search) {
      params.append("search", search);
    }

    let value = params.toString();

    if (value.length > 0) {
      value = `?${value}`;
    }

    if (teamId && organisationId) {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/categories${value}`
      );

      return result.data;
    } else {
      const result = await methods.get(
        `${process.env.REACT_APP_API_URL}/users/${userId}/categories${value}`
      );

      return result.data;
    }
  };

  const create = async (options: {
    organisationId?: string;
    teamId?: string;
    userId?: string;
    category: Category;
  }): Promise<Category> => {
    const { organisationId, teamId, userId, category } = options;

    if (teamId && organisationId) {
      return createForTeam({ organisationId, teamId, category });
    } else if (userId) {
      return createForUser({ category, userId });
    }

    throw new Error("Invalid options provided");
  };

  const createForTeam = async (options: {
    organisationId: string;
    teamId: string;
    category: Category;
  }): Promise<Category> => {
    const { organisationId, teamId, category } = options;
    const projectId = category.projectId;

    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/categories`,
      category
    );
    return result.data;
  };

  const createForUser = async (options: {
    category: Category;
    userId: string;
  }): Promise<Category> => {
    const { category, userId } = options;
    const projectId = category.projectId;

    const result = await methods.post(
      `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/categories`,
      category
    );
    return result.data;
  };

  const update = async (options: {
    organisationId?: string;
    teamId?: string;
    userId?: string;
    category: Category;
  }): Promise<Category> => {
    const { organisationId, teamId, userId, category } = options;

    if (teamId && organisationId) {
      return updateForTeam({ organisationId, teamId, category });
    } else if (userId) {
      return updateForUser({ category, userId });
    }

    throw new Error("Invalid options provided");
  };

  const updateForTeam = async (options: {
    organisationId: string;
    teamId: string;
    category: Category;
  }): Promise<Category> => {
    const { organisationId, teamId, category } = options;
    const projectId = category.projectId;

    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/categories/${category.id}`,
      category
    );
    return result.data;
  };

  const updateForUser = async (options: {
    category: Category;
    userId: string;
  }): Promise<Category> => {
    const { category, userId } = options;
    const projectId = category.projectId;

    const result = await methods.patch(
      `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/categories/${category.id}`,
      category
    );
    return result.data;
  };

  const deleteOne = async (options: {
    organisationId?: string;
    teamId?: string;
    userId?: string;
    categoryId: string;
    projectId: string;
  }): Promise<Category> => {
    const { organisationId, teamId, userId, categoryId, projectId } = options;

    if (teamId && organisationId) {
      return deleteForTeam({ organisationId, teamId, categoryId, projectId });
    } else if (userId) {
      return deleteForUser({ categoryId, userId, projectId });
    }

    throw new Error("Invalid options provided");
  };

  const deleteForTeam = async (options: {
    organisationId: string;
    teamId: string;
    categoryId: string;
    projectId: string;
  }): Promise<Category> => {
    const { organisationId, teamId, categoryId, projectId } = options;
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/organisations/${organisationId}/teams/${teamId}/projects/${projectId}/categories/${categoryId}`
    );
    return result.data;
  };

  const deleteForUser = async (options: {
    categoryId: string;
    userId: string;
    projectId: string;
  }): Promise<Category> => {
    const { categoryId, userId, projectId } = options;
    const result = await methods.deleteOne(
      `${process.env.REACT_APP_API_URL}/users/${userId}/projects/${projectId}/categories/${categoryId}`
    );
    return result.data;
  };

  return {
    list,
    listAll,
    create,
    update,
    deleteOne,
  };
};

export default useCategoriesApi;
