import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import { Info, TrashSimple } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { Category } from "../../../../Api/Resources/Categories/CategoriesApiTypes";
import useCategoryMutation from "../../../../Api/Resources/Categories/CategoriesMutation";
import { TextInput } from "../../../../UIKit/TextInput/TextInput";
import { useParams } from "react-router-dom";
import { useUserId } from "../../../../Store/AuthStore";

export const ModifyCategoryModal = ({
  isOpen,
  onClose,
  category,
  projectId,
}: {
  isOpen: boolean;
  onClose: () => void;
  category?: Category;
  projectId: string;
}) => {
  const userId = useUserId();
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string | undefined>();
  const [billable, setBillable] = useState<boolean | undefined>();
  const { teamId, organisationId } = useParams();
  const { update, create, deleteOne } = useCategoryMutation();

  useEffect(() => {
    if (category) {
      setName(category.name);
      setDescription(category.description);
      setBillable(category.billable);
    }
  }, [category, isOpen]);

  const isUpdating = useMemo(() => {
    return category !== undefined;
  }, [category]);

  const onModifyCategory = () => {
    if (name !== undefined && name !== "") {
      if (isUpdating) {
        update.mutate({
          userId,
          teamId,
          organisationId,
          category: {
            ...category,
            name,
            description,
            projectId,
            billable,
          },
        });
      } else {
        create.mutate({
          userId,
          teamId,
          organisationId,
          category: {
            name,
            description,
            projectId,
            billable,
          },
        });
      }
    } else {
      alert("No name provided!");
    }

    onPrepareModalClose();
  };

  const onPrepareModalClose = () => {
    setName(undefined);
    setDescription(undefined);
    setBillable(undefined);
    onClose();
  };

  const onDeleteCategory = async () => {
    if (category) {
      deleteOne.mutate({
        userId,
        teamId,
        organisationId,
        projectId,
        categoryId: category.id!,
      });
      onPrepareModalClose();
      return;
    }

    throw new Error("No entry to delete.");
  };

  return (
    <>
      <Modal
        size={"lg"}
        isCentered
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {`${isUpdating ? "Update" : "Create"}`} Category
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width={"100%"} spacing={4}>
              <TextInput
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Name"
                label="Name"
                id="name"
              />
              <TextInput
                type="textarea"
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                placeholder="Description"
                label="Description"
                id="description"
              />
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="billable" mb="0">
                  <Flex gap={1} alignItems={"center"}>
                    Is billable?
                    <Tooltip
                      hasArrow
                      label="Do you want to include this category in your project's billable hours?"
                      bg="gray.300"
                      color="black"
                    >
                      <Info />
                    </Tooltip>
                  </Flex>
                </FormLabel>
                <Switch
                  isChecked={billable}
                  onChange={(e: any) => {
                    setBillable(e.target.checked);
                  }}
                  id="billable"
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"space-between"}>
              {isUpdating ? (
                <Button
                  rightIcon={<TrashSimple />}
                  colorScheme="red"
                  variant="outline"
                  mr={3}
                  onClick={onDeleteCategory}
                >
                  Delete
                </Button>
              ) : (
                <Box />
              )}
              <Flex>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button onClick={onModifyCategory} colorScheme="blue">
                  {`${isUpdating ? "Update" : "Create"}`} Category
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
