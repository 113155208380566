import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUsersApi from "../../Api/Resources/Users/UsersApi";

export const ResetPasswordPage = () => {
  const usersApi = useUsersApi();
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [isResetLinkSent, setIsResetLinkSent] = useState<boolean>(false);
  const [resetSuccess, setResetSuccess] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);

  // Extract the token from the query parameters
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const handleRequestResetLink = async () => {
    try {
      await usersApi.requestPasswordReset(email);
      setIsResetLinkSent(true);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage("Failed to send reset link. Please try again.");
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setPasswordMismatch(true);
      return;
    }

    setPasswordMismatch(false);

    try {
      if (token && newPassword) {
        await usersApi.resetPassword({
          token,
          newPassword,
        });
        setResetSuccess(true);
        setErrorMessage(null);
        navigate("/login");
      } else {
        setErrorMessage("Please provide all required information.");
      }
    } catch (error) {
      setResetSuccess(false);
      setErrorMessage("Failed to reset password. Please try again.");
    }
  };

  return (
    <Stack spacing={6} maxW="md" mx="auto" mt="8">
      <Heading fontSize="2xl">Reset Password</Heading>

      {!token ? (
        <>
          <FormControl id="email" isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button onClick={handleRequestResetLink} colorScheme="blue">
            Send Reset Link
          </Button>
          {isResetLinkSent && (
            <Alert status="success" mt={4}>
              <AlertIcon />
              Reset link sent! Check your email.
            </Alert>
          )}
          {errorMessage && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
        </>
      ) : (
        <>
          <FormControl id="newPassword" isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <FormControl id="confirmNewPassword" isRequired>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </FormControl>
          {passwordMismatch && (
            <Alert status="error" mt={2}>
              <AlertIcon />
              Passwords do not match. Please try again.
            </Alert>
          )}
          <Button onClick={handleResetPassword} colorScheme="blue">
            Reset Password
          </Button>
          {resetSuccess && (
            <Alert status="success" mt={4}>
              <AlertIcon />
              Password reset successful! You can now log in with your new
              password.
            </Alert>
          )}
          {errorMessage && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
        </>
      )}
    </Stack>
  );
};

export default ResetPasswordPage;
