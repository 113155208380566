import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { Buildings, Plus } from "phosphor-react";
import TitledPage from "../../../../Components/TitledPage";
import Placeholder from "../../../../UIKit/Placeholder/Placeholder";
import { ModifyOrganisationModal } from "./Components/ModifyOrganisationModal";
import useOrganisationsApi from "../../../../Api/Resources/Organisations/OrganisationsApi";
import { useQuery } from "react-query";
import { useAuthStore } from "../../../../Store/AuthStore";
import shallow from "zustand/shallow";
import { useMemo } from "react";
import ListContainer from "../../../../UIKit/List/List";
import { ListItem, Item, ItemType } from "../../../../UIKit/ListItem/ListItem";
import { useNavigate } from "react-router-dom";

const ListOrganisationsPage = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const organisationsApi = useOrganisationsApi();

  const { authTokens } = useAuthStore(
    (state) => ({ authTokens: state.authTokens }),
    shallow
  );

  const { data: _organisations, isFetching: isFetchingOrganisations } =
    useQuery(["organisations"], () => {
      return organisationsApi.list(authTokens?.userId!);
    });

  const organisations: Item[] = useMemo(() => {
    return (_organisations ?? [])?.map((organisation) => {
      return {
        label: organisation.name ?? "Untitled Organisation",
        description: organisation.description,
        type: ItemType.Item,
        id: organisation.id!,
      };
    });
  }, [_organisations]);

  const onItemSelected = (index: number) => {
    if (organisations) {
      const organisation = organisations[index];
      navigate(`/organisations/${organisation.id}`);
    }
  };

  return (
    <TitledPage
      isResponsive={false}
      isLoading={isFetchingOrganisations}
      title={"Organizations"}
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
      ]}
      action={{
        label: " New Organization",
        icon: Plus,
        trigger: onOpen,
      }}
      showPlaceholder={_organisations?.length === 0}
      placeholderIcon={Buildings}
      placeholderMessage={"No organizations."}
    >
      <ModifyOrganisationModal isOpen={isOpen} onClose={onClose} />

      <>
        {_organisations?.length !== 0 && (
          <ListContainer>
            <>
              <ListItem
                item={{
                  id: "heading",
                  type: ItemType.Header,
                  label: "My Organzations",
                }}
              />
              {(organisations ?? []).map((organisation, index) => {
                return (
                  <ListItem
                    key={index}
                    onClick={() => onItemSelected(index)}
                    item={organisation}
                  />
                );
              })}
            </>
          </ListContainer>
        )}
      </>
    </TitledPage>
  );
};

export default ListOrganisationsPage;
