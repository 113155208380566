import React, { useState } from "react";
import { Box, Text, IconButton, Flex, Image } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "phosphor-react";

import OfficeBuilding from "../../Assets/WhatsNew/office-building.svg"
import SecondsOnAClock from "../../Assets/WhatsNew/seconds-on-a-clock.svg"
import RoadIntoTheDistance from "../../Assets/WhatsNew/road-into-the-distance.svg"
import SyncingBetweenTwoLaptops from "../../Assets/WhatsNew/syncing-between-two-laptops.svg"
import PremiumStar from "../../Assets/WhatsNew/premium-star.svg"
import { keyframes } from "@chakra-ui/react";

// Define the floating animation
const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;


const WhatsNewCarousel = () => {
    const updates = [
        {
            title: "New Feature: Dark Mode Support",
            content: "We now support dark mode! Enable it in the settings menu.",
            image: SecondsOnAClock,
        },
        {
            title: "Performance Improvements",
            content: "Optimized the loading time for the dashboard by 40%.",
            image: OfficeBuilding,
        },
        {
            title: "Bug Fixes",
            content: "Fixed an issue where notifications were not appearing correctly.",
            image: RoadIntoTheDistance,
        },
        {
            title: "New Integration: Google Calendar",
            content:
                "Sync your schedule with Google Calendar directly from your profile.",
            image: SyncingBetweenTwoLaptops,
        },
        {
            title: "Premium Star",
            content:
                "Sync your schedule with Google Calendar directly from your profile.",
            image: PremiumStar,
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prev) => (prev + 1) % updates.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prev) => (prev - 1 + updates.length) % updates.length);
    };

    return (
        <Box
            position="relative"
            w="100vw"
            h="100vh"
            bg="gray.100"
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
        >
            {/* Content */}
            <Flex
                w="100%"
                h="100%"
                bg="white"
                p="8"
                justify="space-between"
                align="center"
                textAlign="left"
            >
                {/* Text Content */}
                <Box zIndex={20}
                    minW={"700px"} p="8">
                    <Text fontSize="4xl" fontWeight="bold" mb="4">
                        {updates[currentIndex].title}
                    </Text>
                    <Text fontSize="lg">{updates[currentIndex].content}</Text>
                </Box>

                {/* Image Content */}
                <Box display="flex" justifyContent="center">
                    <Image
                        maxHeight={"800px"}
                        zIndex={10}
                        marginLeft={"-50px"}
                        minW={"300px"}
                        src={updates[currentIndex].image}
                        alt={updates[currentIndex].title}
                        objectFit="contain"
                        height="90%"
                        borderRadius="md"
                        animation={`${floatAnimation} 3s ease-in-out infinite`} 
                    />
                </Box>
            </Flex>

            {/* Navigation Buttons */}
            <Flex zIndex={40} position="absolute" bottom="4" w="full" justify="space-between" px="4">
                <IconButton
                    aria-label="Previous"
                    icon={<ArrowLeft />}
                    onClick={handlePrev}
                    size="lg"
                    colorScheme="blue"
                />
                <Text fontSize="lg">
                    {currentIndex + 1} / {updates.length}
                </Text>
                <IconButton
                    aria-label="Next"
                    icon={<ArrowRight />}
                    onClick={handleNext}
                    size="lg"
                    colorScheme="blue"
                />
            </Flex>
        </Box>
    );
};

export default WhatsNewCarousel;
