import { Button, useDisclosure } from "@chakra-ui/react";
import { Placeholder, Plus, TrashSimple } from "phosphor-react";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import useProjectMutation from "../../../../Api/Resources/Projects/ProjectsMutation";
import { ModifyProjectModal } from "../../../Projects/List/Components/ModifyProjectModal";
import CustomTable, {
  ColumnType,
  TableColumn,
} from "../../../../UIKit/Table/Table";
import { ButtonTableRow } from "../../../../UIKit/Table/ButtonTableRow";
import { StandardTableRow } from "../../../../UIKit/Table/StandardTableRow";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Team } from "../../../../Api/Resources/Teams/TeamsApiTypes";
import { LinkTableRow } from "../../../../UIKit/Table/LinkTableRow";
import { usePermissions } from "../../../../Store/PermissionStore";
import { Project } from "../../../../Api/Resources/Projects/ProjectsApiTypes";
import { useUserId } from "../../../../Store/AuthStore";
import useProjectsApi from "../../../../Api/Resources/Projects/ProjectsApi";

export const TeamProjectsTab = (props: { team?: Team }) => {
  const { team } = props;
  const userId = useUserId();
  const { teamId, organisationId } = useParams();
  const addProjectModal = useDisclosure();
  const { deleteProject } = useProjectMutation();
  const projectsApi = useProjectsApi();
  const { getPermission } = usePermissions();

  const { data: projects, isFetching: isFetchingProjects } = useQuery(
    ["teamProjects", teamId],
    () => {
      return projectsApi.list({
        teamId,
        organisationId,
        userId,
      });
    }
  );

  const onRemoveProject = (project: Project) => {
    deleteProject.mutate({
      project,
      userId,
    });
  };

  const rows = useMemo(() => {
    return (
      projects?.map((project) => {
        return {
          name: {
            value: project.name,
            link: `/organisations/${organisationId}/teams/${teamId}/projects/${project.id}`,
          },
          lastWorkedOn: "Some project",
        };
      }) ?? []
    );
  }, [projects]);

  const columns = useMemo(() => {
    const columns: TableColumn[] = [
      {
        label: "Name",
        accessor: "name",
        isLink: true,
        type: ColumnType.LINK,
      },
    ];

    if (getPermission("remove_team_project", teamId!)) {
      columns.push({
        label: "Remove",
        accessor: "remove",
        isNumeric: true,
        type: ColumnType.BUTTON,
      });
    }

    return columns;
  }, [getPermission, teamId]);

  return (
    <>
      <ModifyProjectModal
        organisation={team?.organisation}
        team={team}
        allowTeamSelection={false}
        isOpen={addProjectModal.isOpen}
        onClose={addProjectModal.onClose}
      />
      <Card isPadded={false}>
        <CardHeader
          title="Projects"
          subtitle="View, edit and remove team projects."
          action={
            getPermission("add_team_project", teamId!) ? (
              <Button
                leftIcon={<Plus weight="bold" />}
                variant={"outline"}
                colorScheme={"blue"}
                onClick={addProjectModal.onOpen}
              >
                New Project
              </Button>
            ) : undefined
          }
        />

        <CustomTable
          isLoading={isFetchingProjects}
          placeholder={{
            label: "No projects",
            icon: Placeholder,
          }}
          renderRow={(options) => {
            const { column } = options;

            switch (column.type) {
              case ColumnType.BUTTON: {
                return (
                  <ButtonTableRow
                    icon={TrashSimple}
                    onItemClicked={({ rowIndex, columnIndex }) => {
                      if (!projects) return;
                      const project = projects[rowIndex];

                      if (project.id) {
                        onRemoveProject(project);
                      }
                    }}
                    {...options}
                  />
                );
              }
              case ColumnType.LINK: {
                return <LinkTableRow {...options} />;
              }
              default: {
                return <StandardTableRow {...options} />;
              }
            }
          }}
          rows={rows}
          columns={columns}
        />
      </Card>
    </>
  );
};
