import { Box, Heading, UnorderedList, ListItem, Text } from "@chakra-ui/react";
import Page from "../../UIKit/Page/Page";
import PageHeader from "../../UIKit/PageHeader/PageHeader";
import Footer from "../../UIKit/Footer/Footer";

const PrivacyPolicyPage = () => {
    return (
        <>
            <Page sx={{ marginTop: 30, marginBottom: 100 }}>
                <PageHeader sx={{ marginTop: 100, marginBottom: 30 }} title="Privacy Policy" />
                <Box>
                    <Text fontSize="lg" mb={4}>
                        We are committed to protecting the privacy and security of your information. This Privacy Policy
                        explains how we collect, use, and safeguard your data when you use our services.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Information We Collect
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        We collect as little personal information as possible to provide our services effectively. This may include:
                    </Text>
                    <UnorderedList spacing={3} fontSize="lg">
                        <ListItem>
                            <Text as="span" fontWeight="bold">Account Information:</Text> If you create an account, we may collect your name and email address.
                        </ListItem>
                        <ListItem>
                            <Text as="span" fontWeight="bold">Usage Data:</Text> We may collect information about how you use our services, such as the features you use and the time you spend using them. This data is primarily used for improving our services and ensuring proper functionality.
                        </ListItem>
                    </UnorderedList>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        How We Use Your Information
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        We use the information we collect to:
                    </Text>
                    <UnorderedList spacing={3} fontSize="lg">
                        <ListItem>Provide and maintain our services.</ListItem>
                        <ListItem>Improve and personalize your experience.</ListItem>
                        <ListItem>Communicate with you about updates and service-related announcements.</ListItem>
                    </UnorderedList>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Data Security
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        We take the security of your data seriously. We implement appropriate technical and organizational measures, including encryption and security protocols, to protect your information from unauthorized access, use, or disclosure. We follow industry best practices to ensure your data is handled securely. Some key measures include:
                    </Text>
                    <UnorderedList spacing={3} fontSize="lg">
                        <ListItem>
                            <Text as="span" fontWeight="bold">Encryption:</Text> We use encryption both in transit (using HTTPS) and at rest to protect your data.
                        </ListItem>
                        <ListItem>
                            <Text as="span" fontWeight="bold">Access Controls:</Text> We restrict access to your personal information to authorized personnel only.
                        </ListItem>
                    </UnorderedList>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Data Sharing
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        <Text as="span" fontWeight="bold">We do not sell your personal data to third parties.</Text> We may share your information with trusted service providers who assist us in providing our services (e.g., hosting providers), but only to the extent necessary for them to perform their services for us and under strict confidentiality agreements.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Your Rights
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        You may have certain rights regarding your personal information, including the right to access, correct, or delete your data. If you have any questions or requests regarding your data, please contact us.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Changes to This Policy
                    </Heading>
                    <Text fontSize="lg" mb={4}>
                        We may update this Privacy Policy from time to time. We will notify you of any significant changes.
                    </Text>

                    <Heading as="h2" size="lg" mt={8} mb={4}>
                        Contact Us
                    </Heading>
                    <Text fontSize="lg">
                        If you have any questions about this Privacy Policy, please contact us.
                    </Text>
                </Box>
            </Page>
            <Footer />
        </>
    );
};

export default PrivacyPolicyPage;