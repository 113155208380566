import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import useOrganisationsApi from "../../../../Api/Resources/Organisations/OrganisationsApi";
import useTeamsMutation from "../../../../Api/Resources/Teams/TeamsMutation";
import { customSelectStyles } from "../../../../Theme";

export const AddTeamMemberModal = ({
  isOpen,
  onClose,
  organisationId,
  teamId,
}: {
  isOpen: boolean;
  onClose: () => void;
  organisationId?: string;
  teamId?: string;
}) => {
  const organisationsApi = useOrganisationsApi();
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<string>("user");
  const [selectedUser, setSelectedUser] = useState<{
    value: string;
    label: string;
  } | null>();

  const { addTeamMember } = useTeamsMutation();

  const onAddMember = () => {
    if (selectedUser) {
      if (!organisationId || !teamId) {
        throw new Error("Organisation ID or Team ID is missing.");
      }

      addTeamMember.mutate({
        organisationId,
        teamId,
        body: {
          organisationId,
          teamId,
          role,
          userId: selectedUser.value,
        },
      });

      onPrepareModalClose();
    } else {
      throw new Error("No users provided.");
    }
  };

  const onPrepareModalClose = () => {
    setEmail(undefined);
    onClose();
  };

  const loadMembers = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    organisationsApi
      .listOrganisationMembers(organisationId!, inputValue)
      .then((members) => {
        const result = members?.map((member) => {
          return {
            label: member.displayName,
            value: member.id,
          };
        });

        callback(result);
      });
  };

  return (
    <>
      <Modal
        size={"lg"}
        isCentered
        isOpen={isOpen}
        onClose={onPrepareModalClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack width={"100%"} spacing={4}>
              <AsyncSelect
                styles={customSelectStyles}
                isClearable
                placeholder="Select Member"
                value={selectedUser}
                onChange={(value) => setSelectedUser(value)}
                cacheOptions
                defaultOptions={true}
                loadOptions={loadMembers}
              />
              <Select
                value={role}
                onChange={(e: any) => {
                  setRole(e.target.value);
                }}
              >
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
                <option value="user">User</option>
              </Select>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Box />
              <Flex>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button onClick={onAddMember} colorScheme="blue">
                  Add Member
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
