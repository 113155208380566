import {
  Button,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Flex,
  Spinner,
  Tooltip,
  ButtonGroup,
  IconButton,
  FormControl,
  FormLabel,
  Select,
  FormHelperText,
} from "@chakra-ui/react";
import {
  ArrowDown,
  Check,
  CreditCard,
  PencilSimple,
  PencilSimpleLine,
  Plus,
  User,
  Warning,
} from "phosphor-react";
import { useAuthStore, useUserId } from "../../../../Store/AuthStore";
import shallow from "zustand/shallow";
import { Card, CardHeader } from "../../../../UIKit/Card/Card";
import { PlansDialog } from "../../../../UIKit/PlansDialog/PlansDialog";
import TitledPage from "../../../../Components/TitledPage";
import { usePermissions } from "../../../../Store/PermissionStore";
import useBillingApi from "../../../../Api/Resources/Billing/BillingApi";
import { useQuery } from "react-query";
import { BillingTier } from "../../../../Api/Resources/Billing/BillingApiTypes";
import useBillingMutation from "../../../../Api/Resources/Billing/BillingMutation";
import useUsersApi from "../../../../Api/Resources/Users/UsersApi";
import { useEffect, useState } from "react";
import { currencies } from "../../../../constants";
import useUserMutation from "../../../../Api/Resources/Users/UsersMutation";
import UpdateCard from "../UpdateCard";
import { ModifyPersonalDetails } from "./Components/ModifyPersonalDetails";

const FREE_PLAN: BillingTier = {
  id: "free",
  name: "Free",
  level: 0,
  price: {
    id: "free",
    currency: "USD",
    unit_amount: 0,
    unit_amount_decimal: "0",
  },
  features: {
    Entries: "25",
    ["Charge Rates"]: "-",
    Customization: "-",
  },
};

export type UserSettings = {
  currencyCode?: string;
};

const ViewPersonalDetails = () => {
  const userId = useUserId();
  const usersApi = useUsersApi();
  const { update } = useUserMutation();
  const billingApi = useBillingApi();
  const { cancelUserSubscription } = useBillingMutation();
  const { createUserSubscription } = useBillingApi();
  const [isEditingSettings, setIsEditingSettings] = useState(false);

  const { data: user, isFetching: isFetchingUser } = useQuery(
    ["user", userId],
    () => {
      return usersApi.get(userId!);
    }
  );

  const { getPermission } = usePermissions();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenEditProfile, onClose: onCloseEditProfile, onOpen: onOpenEditProfile } = useDisclosure();

  const { data: subscription, isFetching: isFetchingSubscription } = useQuery(
    ["userSubscription", userId],
    () => {
      if (userId) {
        return billingApi.getUserSubscription(userId);
      }

      return;
    }
  );

  useEffect(() => {
    if (user) {
      setUserSettingsData({
        currencyCode: user.currencyCode,
      });
    }
  }, [user]);

  const onSaveSettings = () => {
    update.mutate({
      userId,
      user: {
        currencyCode: userSettingsData?.currencyCode,
      },
    });

    setIsEditingSettings(false);
  };

  const [uneditedUserSettingsData, setUneditedUserSettingsData] =
    useState<UserSettings>();
  const [userSettingsData, setUserSettingsData] = useState<UserSettings>();

  const onCancelEditSettings = () => {
    setUserSettingsData(uneditedUserSettingsData);
    setIsEditingSettings(false);
  };

  const onEditSettings = () => {
    setUneditedUserSettingsData(userSettingsData);
    setIsEditingSettings(true);
  };

  const { data: individualPlans, isFetching: isFetchingIndividualPlans } =
    useQuery(["individualPlans"], () => {
      return billingApi.listIndividualPlans();
    });

  return (
    <TitledPage
      isLoading={isFetchingUser}
      title={"Personal Details"}
      breadcrumbs={[
        {
          label: "Account",
          link: "/account",
        },
        {
          label: "Personal Details",
          link: "/account/personal-details",
        },
      ]}
      action={{
        label: "Edit Details",
        icon: PencilSimple,
        trigger: onOpenEditProfile,
      }}
      placeholderIcon={User}
      placeholderMessage={"No users."}
      showPlaceholder={false}
    >
      <ModifyPersonalDetails
        onClose={onCloseEditProfile}
        isOpen={isOpenEditProfile}
        user={user}
      />
      <VStack
        marginTop={"16px"}
        marginBottom={"64px"}
        spacing={4}
        flexDirection={"column"}
      >
        <Card>
          <CardHeader
            isStandalone={true}
            title="Plan & Billing"
            subtitle={"Your plan and billing information."}
            action={
              <HStack>
                {getPermission("change_user_plan", userId!) && (
                  <Flex
                    gap={2}
                    alignItems={"flex-end"}
                    flexDirection={"column"}
                  >
                    <Flex alignItems={"center"} gap={8}>
                      <Flex alignItems={"center"} gap={2}>
                        <Text fontWeight={"medium"} size={"sm"}>
                          {isFetchingSubscription ? (
                            <Spinner />
                          ) : (
                            subscription?.product?.name ?? "Daybreak Free"
                          )}
                        </Text>
                      </Flex>
                      <PlansDialog
                        onCreateSubscription={async (priceId: string) => {
                          if (!userId) {
                            throw new Error("User ID not found.");
                          }

                          const result = await createUserSubscription({
                            priceId,
                            userId,
                          });

                          return {
                            clientSecret: result.clientSecret,
                          };
                        }}
                        onChangePlan={(planId: string, priceId: string) => { }}
                        redirect={`account/personal-details`}
                        type={"individual"}
                        loading={
                          cancelUserSubscription.isLoading
                            ? FREE_PLAN.price.id
                            : ""
                        }
                        onCancelPlan={() => {
                          const subscriptionId = subscription?.id;

                          if (subscriptionId && userId) {
                            cancelUserSubscription.mutate({
                              subscriptionId,
                              userId,
                            });
                          }
                        }}
                        subscriptionId={subscription?.id}
                        highlightPlan={subscription?.product?.tierId ?? "free"}
                        highlightLevel={subscription?.product?.level ?? 0}
                        plans={
                          individualPlans
                            ? [FREE_PLAN, ...individualPlans]
                            : [FREE_PLAN]
                        }
                        isOpen={isOpen}
                        onClose={onClose}
                      >
                        <Button
                          disabled={isFetchingSubscription}
                          rightIcon={<CreditCard weight="bold" />}
                          variant={"outline"}
                          colorScheme={"blue"}
                          onClick={onOpen}
                        >
                          Change Plan
                        </Button>
                      </PlansDialog>
                    </Flex>
                  </Flex>
                )}
              </HStack>
            }
          />
        </Card>

        {/* TODO: Handle card details update */}
        {/* <UpdateCard /> */}

        <Card>
          <CardHeader
            title={`Settings`}
            subtitle={"Manage the settings for this user."}
            action={
              !isEditingSettings ? (
                <HStack>
                  <Button
                    rightIcon={<PencilSimple weight="bold" />}
                    variant={"outline"}
                    colorScheme={"blue"}
                    onClick={onEditSettings}
                  >
                    Edit Settings
                  </Button>
                </HStack>
              ) : (
                <HStack>
                  <Button variant={"outline"} onClick={onCancelEditSettings}>
                    Cancel
                  </Button>
                  <Button
                    isLoading={update.isLoading}
                    leftIcon={<Check weight="bold" />}
                    colorScheme={"green"}
                    onClick={onSaveSettings}
                  >
                    Save Settings
                  </Button>
                </HStack>
              )
            }
          />
          <FormControl>
            <FormLabel>Currency</FormLabel>
            <Select
              value={userSettingsData?.currencyCode ?? ""}
              onChange={(e) => {
                setUserSettingsData({
                  currencyCode: e.target.value,
                });
              }}
              isDisabled={!isEditingSettings}
              placeholder="Select user currency"
            >
              {Object.values(currencies).map((currency) => (
                <option key={currency.name} value={currency.code}>
                  {currency.name} ({currency.code})
                </option>
              ))}
            </Select>
            <FormHelperText opacity={0.5}>
              This will be used throughout the app where an organisation
              currency is not provided.
            </FormHelperText>
          </FormControl>
        </Card>
      </VStack>
    </TitledPage>
  );
};

export default ViewPersonalDetails;
