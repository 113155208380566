import { Button, Flex, useColorMode } from "@chakra-ui/react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ResponsiveContainer from "../../../../UIKit/ResponsiveContainer/ResponsiveContainer";
import Loading from "../../../../UIKit/Loading/Loading";
import { useState } from "react";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
);

const Form = ({
  isLoading,
  redirect,
}: {
  priceId: string;
  isLoading: boolean;
  redirect?: string;
}) => {
  const [confirming, setConfirming] = useState(false);

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  const confirmPayment = async () => {
    setConfirming(true);

    if (!stripe) {
      throw new Error("Stripe not initialised");
    }

    const { error } = await stripe.confirmPayment({
      elements: elements!,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/${redirect}`,
      },
    });

    setConfirming(false);

    if (error) {
      throw new Error(error.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Flex flexDirection={"column"} alignItems={"flex-end"} gap={4}>
      <ResponsiveContainer paddingTop={"12px"}>
        <div className="grid gap-4 m-auto">
          <PaymentElement />
        </div>
      </ResponsiveContainer>
      <Flex>
        <Button isLoading={confirming} colorScheme="blue" variant={"solid"} onClick={confirmPayment}>
          Confirm Payment Details
        </Button>
      </Flex>
    </Flex>
  );
};

const CheckoutPage = (options: {
  priceId: string;
  orgId?: string;
  orgName?: string;
  type: "individual" | "organisation";
  redirect: string;
  clientSecret?: string;
  loading: boolean;
}) => {
  const { priceId, redirect, clientSecret, loading } = options;
  const { colorMode } = useColorMode();

  if (loading) {
    return <Loading />;
  }

  if (clientSecret) {
    return (
      <Elements
        options={{
          clientSecret,
          appearance: {
            theme: colorMode === "dark" ? "night" : "stripe",
          }
        }}
        stripe={stripePromise}
      >
        <Form
          redirect={redirect}
          priceId={priceId!}
          isLoading={!clientSecret}
        />
      </Elements>
    );
  }

  return null;
};

export default CheckoutPage;
